import { Feature, Overview2 } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataCTAScanningPage as dataCTA,
  dataContactsPage,
  dataFeatureScanningPage as dataFeature,
  dataHeroScanningPage as dataHero,
  dataOverviewScanningPage as dataOverview,
  dataServiceScanningPage as dataService,
  dataSubscribe,
} from "data";
import { Fragment } from "react";
import { Hero2 as Hero } from ".";
import CallToAction from "../../sections/CTA/CallToAction";
import Service from "../../sections/Service";
import { Subscribe } from "../Home";

// NOTE : https://demo.graygrids.com/themes/space/index.html

const ScanningPage = () => {
  return (
    <Fragment>
      <SEO data={dataContactsPage.meta}>
        <link rel="canonical" href="https://elarch.ru/services/scanning/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="scanning-page" id="main">
        <Hero data={dataHero} />
        <Feature data={dataFeature} type="style2" />
        <Overview2 data={dataOverview} />
        <CallToAction data={dataCTA} />
        <Service data={dataService} type="style1" />
        <Subscribe data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default ScanningPage;
