import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

//import * as htmlText from "./Articles/article1.html";
import { Spinner } from "components/utils/Spinner";
import { API_URI, BLOG_ID } from "config/config";
import { useFetch } from "hooks";
import { _debug } from "utils";
import BlogRelatedPosts from "./BlogRelatedPosts";
import SinglePost from "./SinglePost";

const dataRelatedPosts = {
  title: "Похожее в блоге",
  subtitle: "Похожее в блоге",
  description: "Похожее в блоге",
};

const RelatedPosts = () => {
  return (
    <>
      <h3 className="my-4">Related Projects</h3>

      <div className="row">
        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>
      </div>
    </>
  );
};

const PostDetails = (props) => {
  const { details } = props;
  console.log("2", details);
  return (
    <ul>
      {details &&
        details.map((item, index) => <li key={index}>{item.title}</li>)}
    </ul>
  );
};

const SinglePostPage = (props) => {
  const { id = "blog", cn = "pt-130" } = props;
  const { slug } = useParams();
  const URL = `${API_URI}/posts/v2/${slug}?blog=${BLOG_ID}`;
  const {
    data: postData,
    isLoading,
    updateUrl,
    hasError,
    errorMessage,
  } = useFetch(URL);
  //let [htmlFileString, setHtmlFileString] = useState();
  //let [postData, setPostData] = useState();

  _debug(URL);
  _debug(postData);

  useEffect(() => {}, []);

  useEffect(() => {
    //const fetchHtml = async () => {
    //  setHtmlFileString(await (await fetch(postData.file)).text());
    // };
    //if (postData) fetchHtml();
  }, [postData]);

  useEffect(() => {
    _debug("URL updated:", URL);
    updateUrl(URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (postData) _debug("Data fetched: ", postData);
    if (hasError) _debug("Error: ", errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasError, postData]);

  //if (!postData) {
  //  return <span>The blog post you've requested doesn't exist.</span>;
  //}

  if (isLoading || hasError) {
    return (
      <Fragment>
        <Spinner />
        {isLoading && <Spinner />}
        {hasError && <p>{errorMessage}</p>}
      </Fragment>
    );
  }

  if (!postData) return null;

  return (
    <div className="single-posts-wrapper">
      <SinglePost data={postData} />
      <BlogRelatedPosts data={dataRelatedPosts} />
    </div>
  );
};

export default SinglePostPage;
