import { PropTypes } from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";
//import { animateScroll as scroll } from "react-scroll";

const ScrollToTop = (props) => {
  const { top, smooth } = props;

  const [showTopBtn, setShowTopBtn] = useState(false);
  const location = useLocation();

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > top) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    goToTop();
    return () => {};
  }, [location]);

  return (
    <Fragment>
      {showTopBtn && (
        <div className="scroll-top-btn" onClick={goToTop}>
          <FaArrowUp />
        </div>
      )}
    </Fragment>
  );
};

ScrollToTop.propTypes = {
  top: PropTypes.number,
  smooth: PropTypes.bool,
};

ScrollToTop.defaultProp = {
  top: 600,
  smooth: true,
};

export default ScrollToTop;
