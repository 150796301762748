import { BiCapsule } from "react-icons/bi";
import { FaGift } from "react-icons/fa6";
import {
  TfiDashboard,
  TfiInfinite,
  TfiReload,
  TfiServer,
  TfiShortcode,
} from "react-icons/tfi";

/**
 * Data of page "Scanning service" of the site.
 *
 *
 */

export const dataHeroScanningPage = {
  title: "Сканирование и оцифровка документов",
  subtitle: "Услуги по сканированию и оцифровке документов",
  description:
    "Сканирование и переход на электронный документооборот, эффективное управление цифровым контентов - основа для развития вашей организации",
  items: [],
  image: require("assets/images/hero/hero-img (1).webp"),
  imageAlt: "Услуги по сканированию и оцифровке документов",
};

export const dataFeatureScanningPage = {
  title: "Преимущества оцифровки бумажных архивов",
  subtitle: "Для чего использовать?",
  description:
    "Создание цифровой базы документов - основа для цифровизации вашего бизнеса, защита ценной информации и минимизия рисков несанкционированного доступа к документам.",
  items: [
    {
      title: "Освобождение офисных площадей",
      subtitle: "",
      description:
        "Освобождение офиса, магазина, точек обслуживания клиентов и др. от хранения бумажных документов",
      icon: <FaGift />,
      //image: Image1,
      image: null,
      imageAlt: "",
    },
    {
      title: "Удобный доступ",
      subtitle: "",
      description:
        "Быстрый поиск документов любой давности, что экономит рабочее время сотрудников",
      // "Оцифровка документов перед сдачей документов в архив обеспечивает быстрый доступ к данным в любой момент, когда они могут понадобиться."
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Сокращение затрат",
      subtitle: "",
      description:
        "Сканирование документов и предоставление доступа к ним через электронный архив сокращает рабочее время сотрудников на поиск и обработку документов.",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Защита данных",
      subtitle: "",
      description: "Автоматическое наполнение витрины налогового мониторинга",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataOverviewScanningPage = {
  title: "Сканирование и оцифровка",
  subtitle: "Сканирование и оцифровка",
  description:
    "Комплексные услуги по сканированию и оцифровке документов организации",
  items: [
    {
      title: "Документация организации",
      subtitle: "",
      description1:
        "Комплексые услуги по обеспечению эффективности и непрерывности электронного документооборота, сокращение операционных затрат на обработку и обслуживание документов организации.",
      description2: "Мы предлагаем:",
      icon: null,
      image: require("assets/images/scanning/services-1.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description:
              "Комплексный аутсорсинг управления документами в организации. Массовую оцифровку документации, дел по основному профилю деятельности и архивов",
          },
          {
            description:
              "Интеллектуальное извлечение данных и обработку конфиденциальных документов: выделение из текстов значимых сущностей и их нормирование для упрощения поиска информации, составления подборок и пакетов документации для решения отраслевых задач",
          },
          {
            description:
              "Регламентирование процессов документационного обеспечения, разработку стратегий внедрения/модернизации систем архивного эранения и электронного документооборота, развития электронных информационных ресурсов и систем управления ими",
          },
        ],
      },
    },
    {
      title:
        "Собственное программное обеспечение для сканирования и распознавания документов",
      subtitle: "",
      description1:
        "Специализированное программное обеспечение для сканирования и распознавания является самой существенной составляющей в затратах процесса. Мы исключаем эту долю используя собственное ПО.",
      description2: "Мы предлагаем:",
      icon: null,
      image: require("assets/images/scanning/services-2.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description:
              "Массовая оцифровка накопленных папок первичной документации, договоров и других финансовых документов для ввода в корпоративные ERP и CRM системы, создания электронного архива, реализации политик «безбумажной работы» и высвобождения офисного пространства",
          },
          {
            description:
              "Создание участков сканирования и внедрение OCR-модулей собственной разработки для автоматизации сканирования и извлечения данных с первичных документов",
          },
          {
            description:
              "Безопасное хранение и работа с электронными документами, автоматизация предоставления копий на сайт или портал",
          },
        ],
      },
    },
    {
      title: "Автоматическое распознавание и классификация документов",
      subtitle: "",
      description1:
        "Набор программных средств собственной разработки позволяет минимизировать ручной труд. У нас есть стандартный набор шаблонов который позволяет быстро запустить данный процесс для наиболее распространенных видов документов. Если ваши документы нетиповые мы максимально быстро настроим систему под них.",
      description2: "Мы предлагаем:",
      icon: null,
      image: require("assets/images/scanning/services-5.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description:
              "Оцифровка технических паспортов и автоматизация работы с их электронными копиями, сокращает время отработки каждого запроса, увеличить количество обрабатываемых запросов",
          },
          {
            description:
              "Безопасное хранение и работа с электронными документами, автоматизация предоставления копий на сайт или портал",
          },
          {
            description:
              "Создание электронных архивов технической документации промышленных предприятий для обеспечения непрерывности производства и эффективности работы сотрудников конструкторских, производственных, ремонтных и эксплуатационных подразделений",
          },
        ],
      },
    },
    {
      title: "Оцифровка широкоформатных документов",
      subtitle: "",
      description1:
        "Мы оцифровываем чертежи, проекты, карты и документы больших форматов А2, А1, АО, А0+ на профессиональных широкоформатных сканерах.",
      description2: "Мы предлагаем:",
      icon: null,
      image: require("assets/images/scanning/services-3.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description:
              "Изображения имеют высокий уровень детализации. Точность сканирования составляет 0,1% (1 пиксель) между любыми двумя точками на линии сканирования.",
          },
          {
            description:
              "Разрешение сканирования от 200 до 1200 dpi для оригиналов любого размера.",
          },
          {
            description: "",
          },
        ],
      },
    },
  ],
};

export const dataCTAScanningPage = {
  title: "Закажите сканирование документов",
  title1: "Заявка на консультацию",
  title2: "",
  subtitle: "Закажите сканирование документов",
  description:
    "Оформите заявку на сканирование и перевод ваших документов в электронный формат, мы свяжемся с вами в ближайшее время и ответим на все интересующие вопросы.",
  msg: {
    msgCTA: "Отправить",
  },
};

export const dataServiceScanningPage = {
  title: "Комплекс услуг по сканированию и распознаванию",
  subtitle: "Услуги",
  description:
    " At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
  items: [
    {
      title: "Подготовительные работы",
      subtitle: "",
      description:
        "Разброшюровка, маркировка, создание описи дел, нанесение штрих-кодов",
      image: null,
      icon: <BiCapsule />,
    },
    {
      title: "Сканирование",
      subtitle: "",
      description: "Перевод бумажного документа в электронный вид",
      image: null,
      icon: <TfiShortcode />,
    },
    {
      title: "Текстовое распознование",
      subtitle: "",
      description:
        "Перевод графических образов в редактируемый (машинописный) вид для быстрого поиска слова или значения в тексте сканированного документа",
      image: null,
      icon: <TfiReload />,
    },
    {
      title: "Индексирование данных",
      subtitle: "",
      description:
        "Обработка документов по заданному составу полей является ключевым, если требуется осуществлять поиск документов по различным параметрам",
      image: null,
      icon: <TfiServer />,
    },
    {
      title: "Формирование электронных книг",
      subtitle: "",
      description:
        "Создание удобных и привычных для использования документов офисного типа (PDF, DOC)",
      image: null,
      icon: <TfiInfinite />,
    },
    {
      title: "Экспорт данных",
      subtitle: "",
      description:
        'Загрузка данных в электронный архив на базе решений "ELARCH" или корпоративную информационную систему',
      image: null,
      icon: <TfiDashboard />,
    },
  ],
};
