import placeholderSrc from "assets/images/hello-world.webp";
import { ProgressiveImg } from "components/utils";
import { formatDataShort } from "components/utils/Utils";
import { API_URI, BLOG_ID } from "config/config";
import { dataForm } from "data";
import { PropTypes } from "prop-types";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const POST_URL = `${API_URI}/posts`;

/**
 * Component for showing Section "Post Card" of the site.
 *
 * @component
 */
const BlogPostCard = (props) => {
  const { data, index, useAnimation } = props;
  console.log(data);

  return (
    <div className="col-xl-4 col-lg-4 col-md-6">
      <div
        className="single-blog mb-40 aos-animate"
        {...(useAnimation && {
          "data-aos": "fade-up",
          "data-aos-delay": 200,
        })}
      >
        <div className="blog-img">
          <Link
            to={`/blog/${data.id}?blog=${BLOG_ID}`}
            aria-label={`blog_${data.id}`}
          >
            <ProgressiveImg
              src={`${POST_URL}/imagedb/${data.imageURL}`}
              placeholderSrc={placeholderSrc}
              alt=""
            />
          </Link>
          <Link to={`/blog/category/${data.categoryId}?blog=${BLOG_ID}`}>
            <span className="date-meta">{data.categoryName}</span>
          </Link>
        </div>
        <div className="blog-content">
          <div className="small text-muted mb-2">
            {formatDataShort(
              data.updatedDate ? data.updatedDate : data.createdDate
            )}
          </div>

          <Link to={`/blog/${data.slug}?blog=${BLOG_ID}`}>
            <h4>{data.title}</h4>
          </Link>

          <p>{data.description}</p>
          <Link
            to={`/blog/${data.slug}?blog=${BLOG_ID}`}
            className="btn read-more-btn"
          >
            {dataForm.msgReadMore} <AiOutlineArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

BlogPostCard.propTypes = {
  data: PropTypes.object,
  useAnimation: PropTypes.bool ?? false,
};

BlogPostCard.defaultProps = {
  useAnimation: false,
};

export default BlogPostCard;
