import { Loading } from "components/utils";
import { SEO } from "components/utils/Analytics";
import { dataBlogPage } from "data";
import { Fragment, Suspense } from "react";
import { Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { Banner } from "../../sections/Banner";
import { PageSection } from "../Home";
import BlogCategories from "./BlogCategories";
import BlogSocial from "./BlogSocial";
import BlogTopTags from "./BlogTopTags";

// NOTE : https://preview.uideck.com/items/cryptoland/

const SectionContent = (props) => {
  const { data } = props;

  // NOTE : https://github.com/CodeCompleteYT/react-search-bar/blob/main/src/components/SearchResult.jsx

  return (
    <Row>
      <div className="col-xl-8 col-lg-7">
        <div className="left-side-wrapper">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <div className="col-xl-4 col-lg-5">
        <div className="sidebar-wrapper">
          <Suspense fallback={<Loading />}>
            <BlogCategories />
          </Suspense>
          <Suspense fallback={<Loading />}>
            <BlogTopTags />
          </Suspense>
          <BlogSocial />
        </div>
      </div>
    </Row>
  );
};

/**
 * Component for showing Page "Blog Layout" of the site.
 *
 * @component
 */
const BlogLayout = () => {
  return (
    <Fragment>
      <SEO data={dataBlogPage.meta}>
        <link rel="canonical" href="https://elarch.ru/blog/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-blog">
        <Banner data={dataBlogPage} />
        <PageSection id="blog-post-list" cn="blog-section pt-130">
          <div className="container">
            {/*<SectionHeader data={dataSection}  />*/}
            <SectionContent />
          </div>
        </PageSection>
      </main>
    </Fragment>
  );
};

export default BlogLayout;
