import { VideoModal } from "components/sections/TypicalSections";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { FaPlay, FaArrowRight as Icon1 } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { PageSection } from "../../sections/CommonSection";

import ImageDots from "assets/images/hero/dots.shape.svg";

//import Image1_2 from "assets/dots.shape.svg";

const msgGetStarted = "Заказать";
const videoURL = "https://www.youtube.com/embed/C0DPdy98e4c";

const SectionContent = (props) => {
  const { data, ...otherProps } = props;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="row">
      <div className="col-xl-5 col-lg-6">
        <div className="hero-content-wrapper">
          <h2
            className="hero-content-subtitle aos-animate"
            data-aos="fade-down"
            data-aos-delay="400"
          >
            {data.subtitle}
          </h2>
          <h1
            className="hero-content-title aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {data.title}
          </h1>
          <p
            className="hero-content-description aos-animate"
            data-aos="fade-left"
            data-aos-delay="600"
          >
            {data.description}
          </p>
          <div className="hero-content-control d-flex justify-content-center">
            <button
              onClick={otherProps.onScroll}
              aria-label="Lets start"
              className="btn theme-btn"
            >
              {msgGetStarted}
              <Icon1 />
            </button>
          </div>
        </div>
      </div>
      <div className="col-xl-7 col-lg-6">
        <div className="hero-img">
          <div className="d-inline-block hero-img-right">
            <img
              src={data.image}
              alt={data.imageAlt}
              className="hero-image aos-animate"
              data-aos="fade-right"
              data-aos-delay="500"
            />
          </div>

          <img src={ImageDots} alt={data.imageAlt} className="dot-shape" />
          <div className="btn video-btn">
            <Link
              to="/"
              onClick={openModal}
              aria-label="Show video"
              className="glightbox"
            >
              <FaPlay />
            </Link>
          </div>
        </div>
      </div>
      <VideoModal
        isOpen={isOpen}
        handlerCloseModal={closeModal}
        videoURL={videoURL}
      />
    </div>
  );
};

/**
 * Component for showing Section "Hero" of the site.
 *
 * @component
 */
const Hero = (props) => {
  const { data, id = "home", cn = "hero-section", ...otherProps } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent data={data} {...otherProps} />
      </div>
    </PageSection>
  );
};

Hero.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Hero;
