import classnames from "classnames";
import { PropTypes } from "prop-types";
import { PageSection, SectionHeader } from "./CommonSection";

const ContentItem = (props) => {
  const { data, type } = props;

  return (
    <div className="col-lg-4 col-md-6">
      <div
        className={classnames(
          type === "style2" ? "service-box-style-2" : "service-box",
          "box-style"
        )}
      >
        {type === "style2" && (
          <div className="service-thumbnail">
            <img src={data.image} alt="" />
          </div>
        )}
        <div className="service-icon box-icon">{data.icon}</div>
        <div className="box-content service-content">
          <h4>{data.title}</h4>
          <p>{data.description}</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, type } = props;
  return (
    <div className="row equal">
      {data.items.map((item, index) => {
        return <ContentItem data={item} key={index} type={type} />;
      })}
    </div>
  );
};

/**
 * Component for showing Section "Service" of the site.
 *
 * @component
 */
const Service = (props) => {
  const { data, id = "service", cn = "pt-120 pb-40", type } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} type={type} />
      </div>
    </PageSection>
  );
};

Service.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
};

Service.defaultProp = {
  type: "style1",
};

export default Service;
