import { Link } from "react-router-dom";

export const LinkToPost = (props) => {
  const { slug, otherProps } = props;

  return (
    <Link to={`/blog/${slug}`} {...otherProps}>
      {props.children}
    </Link>
  );
};
