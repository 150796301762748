import { Loading } from "components/utils";
import { InitializeGA4, InitializeYA } from "components/utils/Analytics";
import { AuthProvider } from "contexts/AuthProvider";
import { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

// Importing site Fonts
import "../styles/fonts/Glory/Glory-VariableFont_wght.ttf";
import "../styles/fonts/Roboto/Roboto-Black.ttf";

// Bootstrap import move to SASS and main.css
// Importing the Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Importing main style
import "../styles/css/main.css";

import { ErrorSection } from "components/pages/NotFound";
import { useDeviceType } from "hooks";
import { _debug } from "utils";

// Diasable i18n
//import "./i18n";

InitializeGA4();
//InitializeYA();

const helmetContext = {};

const App = () => {
  const [loading, setLoading] = useState(false);
  const deviceType = useDeviceType();

  _debug(deviceType);

  useEffect(() => {
    //setLoading(true);
    //setTimeout(() => {
    //  setLoading(false);
    //}, 1000);
  }, []);

  return (
    <ErrorBoundary fallback={ErrorSection}>
      <InitializeYA />
      <AuthProvider>
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <AppRoutes />
            </Suspense>
          </BrowserRouter>
        </HelmetProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;

