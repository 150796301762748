import {
  ContentSiteEmail,
  ContentSiteOfferPolicy,
} from "components/utils/Utils";
import { dataSite } from "data";
import { PropTypes } from "prop-types";
import { PageSection } from "../Home";

const SectionContent = () => {
  return (
    <div className="content-pay">
      <h2 className="title">Политика проведения оплат и расчетов</h2>
      <p className="subtitle">Последнее обновление: 10.07.2023 г.</p>
      <h3 className="content-title">Оплата переводом на расчетный счет</h3>
      <p>
        Вы можете оплатить заказ переводом на расчетный счет. Счет на оплату
        необходимо запросить у специалиста по телефону
        <strong>{dataSite.main.phone}</strong> или через электронную почту{" "}
        <ContentSiteEmail />. Закрывающие документы будут отправлены на
        электронный адрес, указанный при регистрации. Оригиналы документов
        предоставляются по запросу на адрес <ContentSiteEmail />.
      </p>
      <p>
        Страницы на баланс будут начислены после получения денег на расчетный
        счет организации.
      </p>
      <h3 className="content-title">Оплата банковской картой</h3>
      <p>
        Так же юридические и физические лица имеют возможность оплатить заказ с
        помощью банковской карты. Для формирования ссылки на оплату банковской
        картой обратитесь по телефону
        <strong>{dataSite.main.phone}</strong> или через электронную почту{" "}
        <ContentSiteEmail />.
      </p>
      <p>Оплата банковскими картами осуществляется через АО «АЛЬФА-БАНК».</p>
      <p>
        К оплате принимаются карты <strong>VISA, MasterCard, МИР</strong>.
      </p>
      <p>
        Услуга оплаты через интернет осуществляется в соответствии с Правилами
        международных платежных систем Visa, MasterCard и Платежной системы МИР
        на принципах соблюдения конфиденциальности и безопасности совершения
        платежа, для чего используются самые современные методы проверки,
        шифрования и передачи данных по закрытым каналам связи. Ввод данных
        банковской карты осуществляется на защищенной платежной странице АО
        «АЛЬФА-БАНК».
      </p>
      <p>
        На странице для ввода данных банковской карты потребуется ввести данные
        банковской карты: номер карты, имя владельца карты, срок действия карты,
        трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код
        Дополнительной Идентификации для МИР). Все необходимые данные
        пропечатаны на самой карте. Трёхзначный код безопасности — это три
        цифры, находящиеся на обратной стороне карты.
      </p>
      <p>
        Далее вы будете перенаправлены на страницу Вашего банка для ввода кода
        безопасности, который придет к Вам в СМС. Если код безопасности к Вам не
        пришел, то следует обратиться в банк выдавший Вам карту.
      </p>
      <p>
        Случаи отказа в совершении платежа:
        <ul>
          <li>
            банковская карта не предназначена для совершения платежей через
            интернет, о чем можно узнать, обратившись в Ваш Банк;
          </li>
          <li>
            недостаточно средств для оплаты на банковской карте. Подробнее о
            наличии средств на банковской карте Вы можете узнать, обратившись в
            банк, выпустивший банковскую карту;
          </li>
          <li>данные банковской карты введены неверно;</li>
          <li>
            истек срок действия банковской карты. Срок действия карты, как
            правило, указан на лицевой стороне карты (это месяц и год, до
            которого действительна карта). Подробнее о сроке действия карты Вы
            можете узнать, обратившись в банк, выпустивший банковскую карту;
          </li>
        </ul>
      </p>
      <p>
        По вопросам оплаты с помощью банковской карты и иным вопросам, связанным
        с работой сайта, Вы можете обращаться по следующим телефонам:{" "}
        <strong>{dataSite.main.phone}</strong>.
      </p>
      <p>
        Предоставляемая вами персональная информация (имя, адрес, телефон,
        e-mail, номер банковской карты) является конфиденциальной и не подлежит
        разглашению. Данные вашей кредитной карты передаются только в
        зашифрованном виде и не сохраняются на нашем Web-сервере.
      </p>
      <h3 className="content-title">Правила возврата товара</h3>
      <p>
        При оплате картами возврат наличными денежными средствами не
        допускается. Порядок возврата регулируется правилами международных
        платежных систем.
      </p>
      <p>
        Процедура возврата товара регламентируется статьей 26.1 федерального
        закона «О защите прав потребителей» и договором-офертой размещенным по
        ссылке <ContentSiteOfferPolicy />
      </p>
      <p>
        Расчет денежных средств, подлежащих возврату, осуществляется по формуле:
      </p>
      <p style={{ marginLeft: "1em" }}>
        <strong>﻿E=(A/12*(12-B)*C/D)</strong>, где
        <br />
        <strong>A</strong> - количество неиспользованных страниц пакета;
        <br />
        <strong>B</strong>- количество месяцев, прошедших со дня приобретения
        пакета страниц;
        <br />
        <strong>C</strong> - стоимость приобретенного пакета страниц;
        <br />
        <strong>D</strong> - количество страниц, приобретенных в пакете;
        <br />
        <strong>E </strong>– размер денежных средств, подлежащих возврату.
      </p>
      <p>
        Для возврата денежных средств на банковскую карту необходимо заполнить
        «Заявление о возврате денежных средств», которое высылается по
        требованию компанией на электронный адрес и оправить его вместе с
        приложением копии паспорта по адресу <ContentSiteEmail />.
      </p>
      <p>
        Возврат денежных средств будет осуществлен на банковскую карту в течение
        21 (двадцати одного) рабочего дня со дня получения «Заявление о возврате
        денежных средств» Компанией.
      </p>
      <p>
        Для возврата денежных средств по операциям проведенными с ошибками
        необходимо обратиться с письменным заявлением и приложением копии
        паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное
        заявление необходимо направить по адресу <ContentSiteEmail />.
      </p>
      <p>
        Срок рассмотрения Заявления и возврата денежных средств начинает
        исчисляться с момента получения Компанией Заявления и рассчитывается в
        рабочих днях без учета праздников/выходных дней.
      </p>
      <h3 className="content-title">Правила доставки товара</h3>
      <p>
        Наличие на электронной витрине Интернет-магазина информации по доставке
        товара (получении работы, услуги), такой, как сроки, способы, а также
        любой другой информации, необходимой для получения ясного представления
        о доставке товара (получении работы, услуги) после оплаты с
        использованием карты.
      </p>
      <h3 className="content-title">Контактная информация</h3>
      <p>Контактная информация:</p>
      <p>
        Название компании: {dataSite.main.name}
        <br />
        ИНН/КПП: {dataSite.main.INN}/{dataSite.main.KPP}
        <br />
        Фактический адрес: {dataSite.main.address}
        <br />
        Телефон: {dataSite.main.phone}
        <br />
        e-mail: {dataSite.main.email}
      </p>
    </div>
  );
};

const SectionPay = (props) => {
  const { data, id = "pay", cn = "pt-80 pb-100 site-content" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent />
      </div>
    </PageSection>
  );
};

SectionPay.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default SectionPay;
