//import { Form as FormValidator } from "components/utils/Forms/Forms";
import { useAuth } from "contexts/AuthProvider";
import { dataSite } from "data";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { Link, Navigate } from "react-router-dom";
import "./AuthPage.css";

// NOTE : https://demo.bootstrapdash.com/login-templates-pro/login-8/#!

const AuthPage = () => {
  let [authMode, setAuthMode] = useState("signin");

  const { auth, setAuthData, isLogin, signIn, logout, register } = useAuth();

  const [username, setUsername] = useState("User1");
  const [email, setEmail] = useState("user1@local.loc");
  const [password, setPassword] = useState("user123");
  const [remember, setRemember] = useState(false);
  const [validate, setValidate] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const data = {
    title: "Welcome to website!",
    subtitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    copyright: "Copyright 2024 bootstrapdash",
  };

  const labels = {};

  const handleChangeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };

  const validateLogin = () => {
    let isValid = true;

    let validator = null;
    //FormValidator.validator({
    //  email: {
    //    value: email,
    //    isRequired: true,
    //    isEmail: true,
    //  },
    //  password: {
    //    value: password,
    //    isRequired: true,
    //    minLength: 6,
    //  },
    //});

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const handleAuthenticate = (e) => {
    e.preventDefault();

    const validate = validateLogin();

    if (validate) {
      if (authMode === "signin") {
        //setAuthData({ email: email, password: password, remember: remember });
        signIn({ email: email, password: password, remember: remember });
      } else {
        //setAuthData({
        //  email: email,
        //  username: username,
        //  password: password,
        //  remember: remember,
        //});
        register({
          email: email,
          username: username,
          password: password,
          remember: remember,
        });
      }

      setValidate({});
      //setEmail("");
      //setPassword("");
      //alert("Successfully Login");
    }
  };

  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const LoginForm = (props) => {
    return (
      <Form
        className="auth-form"
        method="POST"
        onSubmit={handleAuthenticate}
        autoComplete={"off"}
      >
        <Form.Group controlId="form.email" className="form-group">
          <Form.Label className="sr-only">Email address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            className={`${
              validate.validate && validate.validate.email ? "is-invalid " : ""
            }`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text
            id="emailHelpBlock"
            muted
            hidden={!(validate.validate && validate.validate.email)}
          >
            Your email must contain letters and numbers, and must not contain
            spaces, special characters, or emoji.
          </Form.Text>
          <div
            className={`invalid-feedback text-start ${
              validate.validate && validate.validate.email
                ? "d-block"
                : "d-none"
            }`}
          >
            {validate.validate && validate.validate.email
              ? validate.validate.email[0]
              : ""}
          </div>
        </Form.Group>

        {authMode === "signup" && (
          <Form.Group controlId="form.username" className="form-group mb-3">
            <Form.Label className="sr-only">Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Username"
              className={`${
                validate.validate && validate.validate.username
                  ? "is-invalid "
                  : ""
              }`}
              value={username}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text
              id="emailHelpBlock"
              muted
              hidden={!(validate.validate && validate.validate.username)}
            >
              Your username must contain letters and numbers, and must not
              contain spaces, special characters, or emoji.
            </Form.Text>
            <div
              className={`invalid-feedback text-start ${
                validate.validate && validate.validate.username
                  ? "d-block"
                  : "d-none"
              }`}
            >
              {validate.validate && validate.validate.username
                ? validate.validate.username[0]
                : ""}
            </div>
          </Form.Group>
        )}
        <Form.Group controlId="form.password" className="form-group mb-3">
          <Form.Label className="sr-only">Password</Form.Label>
          <InputGroup className="">
            <Form.Control
              type={showPassword ? "text" : "password"}
              className={`${
                validate.validate && validate.validate.password
                  ? "is-invalid "
                  : ""
              }`}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              aria-label="User's password"
              aria-describedby="basic-addon2"
            />
            <Button
              variant="outline-secondary"
              onClick={(e) => togglePassword(e)}
              id="button-addon2"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </Button>
          </InputGroup>
          <Form.Text
            id="passwordHelpBlock"
            muted
            hidden={!(validate.validate && validate.validate.email)}
          >
            Your password must be 8-20 characters long, contain letters and
            numbers, and must not contain spaces, special characters, or emoji.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="remember" className="login-check-box">
          <Form.Check
            type="checkbox"
            label="Remember Me"
            id="remember"
            checked={remember}
            onChange={(e) => setRemember(e.currentTarget.checked)}
          />
        </Form.Group>
        <div className="d-flex justify-content-between align-items-center mb-5">
          <Button variant="primary" type="submit" className="btn login-btn">
            {authMode === "signin" ? "Login" : "Register"}
          </Button>
          <div className="forgot-password text-end">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
        </div>
      </Form>
    );
  };

  const SocialLoginLinks = () => {
    return (
      <nav className="social-login-links">
        <Link className="social-login-link">
          <img src={require("assets/icons/facebook.svg")} alt="facebook logo" />
          Facebook
        </Link>
        <Link href="#!" className="social-login-link">
          <img src={require("assets/icons/twitter.svg")} alt="twitter logo" />
          Twitter
        </Link>
        <Link href="#!" className="social-login-link">
          <img src={require("assets/icons/google.svg")} alt="google logo" />
          Google
        </Link>
      </nav>
    );
  };

  return (
    <main className="page-auth-login">
      {isLogin && <Navigate to="/" replace={true} />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-5 intro-section">
            <div className="brand-wrapper">
              <img
                src={require("assets/icons/logo (2).svg")}
                alt=""
                className="logo"
              />
            </div>
            <div className="intro-content-wrapper">
              <h1 className="intro-title">{data.title}</h1>
              <p className="intro-text">{data.description}</p>
            </div>
            <div className="intro-section-footer">
              <div className="copyright">
                © Copyright {dataSite.main.copyrightYear}{" "}
                <strong>
                  <span className="copyright-text">
                    {dataSite.main.copyrightText}
                  </span>
                </strong>
              </div>
            </div>
          </div>
          <div className="col-sm-7 form-section">
            <div className="login-wrapper-signup-text text-center">
              {authMode === "signin"
                ? "Need an account?"
                : "Already registered?"}{" "}
              <span className="link-primary" onClick={handleChangeAuthMode}>
                {authMode === "signin" ? "Sign In" : "Sign Up"}
              </span>
            </div>

            <div className="login-wrapper">
              <h2 className="login-title">
                {authMode === "signin" ? "Sign In" : "Sign Up"}
              </h2>
              <LoginForm onChangeAuthMode={handleChangeAuthMode} />
              <h6 className="social-login-title">OR</h6>
              <SocialLoginLinks />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthPage;
