import { BiPhone, BiSupport } from "react-icons/bi";
import { dataSite } from "./dataSite";

export const dataForm = {
  msgReadMore: "Подробнее",
  msgSubmitted: "Сообщение успешно отправлено",
  msgSubmit: "Отправить сообщение",
  msgPolicyAgreed: "Принять",

  name: {
    msgLebel: "Имя",
    msgHelp: "Укажите ваше имя",
    msgPlaceholder: "Ваше имя",
    msgRequired: "Требуется ввести имя",
  },

  email: {
    msgLebel: "Почта",
    msgHelp: "Укажите вашу электронную почту",
    msgPlaceholder: "Ваша почта",
    msgRequired: "Требуется ввести адрес электронной почты",
  },

  message: {
    msgLebel: "Сообщение",
    msgMessage: "Сообщение",
    msgHelp: "Введите ваше сообщение",
    msgPlaceholder: "Сообщение",
    msgRequired: "Требуется ввести текст сообщения",
  },

  subject: {
    msgLebel: "ема сообщения",
    msgHelp: "Выберите тему сообщения",
    msgPlaceholder: "Тема сообщения",
    msgRequired: "Требуется указать тему сообщения",
  },

  phone: {
    msgLebel: "Телефон",
    msgHelp: "Укажите ваш телефон",
    msgPlaceholder: "Телефон",
    msgRequired: "Требуется ввести телефон",
  },

  msgSubscriptionCreated: "Вы успешно подписались на обновления!",
  megSubscribe: "Подписаться",

  errors: {},
};

export const dataSocialLinks = [
  {
    link: "https://rbc.ru",
    type: "fb",
    status: false,
    name: "Facebook",
  },
  {
    link: "https://rbc.ru",
    type: "ln",
    status: false,
    name: "LinkedIn",
  },
  {
    link: "https://www.youtube.com/@elarchecm",
    type: "yt",
    status: true,
    name: "Youtube",
  },
  {
    link: "https://dzen.ru/elarch_ecm",
    type: "dz",
    status: true,
    name: "DZen",
  },
  {
    link: "https://t.me/elarch_ecm",
    type: "tg",
    status: true,
    name: "Telegram",
  },
  {
    link: "https://rbc.ru",
    type: "tw",
    status: false,
    name: "Tweeter",
  },
  {
    link: "https://vk.com/elarch_ecm",
    type: "vk",
    status: true,
    name: "VK",
  },
];

export const dataTopMenu = [
  { path: "/home", title: "Главная", showOnlyWhenLoggedIn: false },
  { path: "/blog", title: "Блог", showOnlyWhenLoggedIn: false },
  //{ path: "/faq", title: "F.A.Q.", showOnlyWhenLoggedIn: false },
  { path: "/faq", title: "Вопросы и ответы", showOnlyWhenLoggedIn: false },
  { path: "/contacts", title: "Контакты", showOnlyWhenLoggedIn: false },
  { path: "/about", title: "О Компании", showOnlyWhenLoggedIn: false },

  { path: "/profile", title: "Профиль", showOnlyWhenLoggedIn: true },
  { path: "/admin", title: "Admin", showOnlyWhenLoggedIn: true },
  { path: "/auth", title: "Login", showOnlyWhenLoggedIn: true },
  //{ path: '/register', title: 'Register', showOnlyWhenLoggedIn: true }
];

export const dataTopMenuMain = [
  {
    path: "mailto:info@elarch.ru",
    title: "info@elarch.ru",
    showOnlyWhenLoggedIn: false,
    showIcon: true,
    icon: <BiSupport />,
  },
  {
    path: dataSite.main.phoneLink,
    title: dataSite.main.phone,
    showOnlyWhenLoggedIn: false,
    showIcon: true,
    icon: <BiPhone />,
  },
];

export const dataTopMenuSupport = [
  { path: "/faq", title: "Задать вопрос?", showOnlyWhenLoggedIn: false },
  {
    path: "mailto:help@elarch.ru",
    title: "help@elarch.ru",
    showOnlyWhenLoggedIn: false,
  },
  {
    path: "https://t.me/agvento-test",
    title: "Telegram",
    showOnlyWhenLoggedIn: false,
  },
  {
    path: "https://wa.me/4957777777",
    title: "WhatsApp",
    showOnlyWhenLoggedIn: false,
  },
  {
    path: dataSite.main.phoneLink,
    title: dataSite.main.phone,
    showOnlyWhenLoggedIn: false,
  },
];

export const dataFooterMenu = [
  { path: "/home", title: "Главная", showOnlyWhenLoggedIn: false },
  { path: "/blog", title: "Блог", showOnlyWhenLoggedIn: false },
  //{ path: "/faq", title: "F.A.Q.", showOnlyWhenLoggedIn: false },
  { path: "/faq", title: "Вопросы и ответы", showOnlyWhenLoggedIn: false },
  { path: "/contacts", title: "Контакты", showOnlyWhenLoggedIn: false },
  { path: "/about", title: "О Компании", showOnlyWhenLoggedIn: false },
];

export const dataFooterServices = [
  { path: "/archive", title: "Электронный архив", showOnlyWhenLoggedIn: false },
  { path: "/services", title: "Наши услуги", showOnlyWhenLoggedIn: false },
  // { path: "/home", title: "Внедрение", showOnlyWhenLoggedIn: false },
  {
    path: "/services/scanning",
    title: "Сканирование",
    showOnlyWhenLoggedIn: false,
  },
  // { path: "/home", title: "Распознавание", showOnlyWhenLoggedIn: false },
  {
    path: "/partners",
    title: "Партнерство",
    showOnlyWhenLoggedIn: false,
  },
  //{ path: "/faq", title: "Веь дизайн", showOnlyWhenLoggedIn: false },
];
