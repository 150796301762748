import { About, Banner, Subscribe } from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataAbout,
  dataAboutPage,
  dataContact,
  dataSubscribe,
  dataTeam,
} from "data";
import React, { Fragment } from "react";

const SectionContact = React.lazy(() => import("../Contact/SectionContact"));
//const Service = React.lazy(() => import("../../sections/Service"));
const Location = React.lazy(() => import("./Location"));
const Team = React.lazy(() => import("./Team"));

/**
 * Component for showing Page "About" of the site.
 *
 * @component
 */

const AboutPage = () => {
  return (
    <Fragment>
      <SEO data={dataAboutPage.meta}>
        <link rel="canonical" href="https://elarch.ru/about/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-about" id="main">
        <Banner data={dataAboutPage} />
        <About data={dataAbout} type="style1" />
        <Location />
        <Team data={dataTeam} />
        <SectionContact data={dataContact} />
        <Subscribe data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default AboutPage;
