//import { SocialButtons } from "../../utils/Buttons/SocialButtons";
import "./Countdown.scss";
import CountdownTimer from "./CountdownTimer";

/**
 * Component for showing Countdown Timer of the site.
 *
 * https://preview.uideck.com/items/bolt-demo/
 *
 * @component
 */

const Countdown = (props) => {
  const { title, subtitle, description } = props;

  //const dateTimeLocale = ["day", "hour", "min", "sec"];
  const dateTimeLocale = ["дней", "часов", "минут", "секунд"];

  //const Social = () => {
  //   return (
  //    <div className="social-group countdown-social">
  //      <SocialButtons data={dataSocial} cn="black-icon" />
  //    </div>
  // );
  //};  // <Social />

  return (
    <section className="site-section section-countdown-timer">
      <div className="countdown-timer-wrapper">
        <div className="bg-wraper"></div>
        <div className="countdown-timer">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12 col-sm-12 col-xs-12">
                {title && <h1 className="countdown-timer-title">{title}</h1>}
                {subtitle && (
                  <h2 className="countdown-timer-subtitle">{subtitle}</h2>
                )}
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <CountdownTimer dateTimeLocale={dateTimeLocale} />
                </div>
                {description && (
                  <p className="countdown-timer-description">{description}</p>
                )}
                <div className="button-group"></div>
                <div className="controll-group">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Countdown;
