import { SEO } from "components/utils/Analytics";
import { dataNotFoundPage } from "data";
import { Fragment } from "react";
import NotFoundSection from "./NotFoundSection";

/**
 * Component for showing Page "Not Found - 404" of the site.
 *
 * @component
 */
const NotFoundPage = () => {
  return (
    <Fragment>
      <SEO data={dataNotFoundPage.meta}>
        {/*Don’t canonicalise to a redirected page or a 404 page
         *<link rel="canonical" href="https://elarch.ru/not-found/" />
         */}
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-not-found">
        <NotFoundSection data={dataNotFoundPage} />
      </main>
    </Fragment>
  );
};

export default NotFoundPage;
