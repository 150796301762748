import { ContentSiteEmail } from "components/utils/Utils";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { PageSection } from "../Home";

const SectionContent = (props) => {
  const { data } = props;
  const location = useLocation();

  return (
    <Row className="h-100">
      <Col lg="12">
        <div className="page-not-foud-content-wrapper text-center">
          <h3 className="mb-3">{data.subtitle}</h3>
          <h1 className="mb-5">{data.title}</h1>
          <h2 className="d-none">Page Not Found - 404!</h2>
          <h2 className="mb-3">
            Запрашиваемые путь <code>{location.pathname}</code>
          </h2>
          <p className="mb-2">{data.description}</p>
          <p className="mb-3">
            Вернитесь на <Link to="/">Главную</Link> или напишите нам на{" "}
            <ContentSiteEmail />
          </p>
          <Link to="/" className="btn theme-btn">
            Главная
          </Link>
        </div>
      </Col>
    </Row>
  );
};

/**
 * Component for showing Section "Error" of the site.
 *
 * @component
 */
const ErrorSection = (props) => {
  const {
    error,
    resetErrorBoundary,
    data,
    id = "error",
    cn = "pt-180 h-100",
  } = props;

  console.log(props);

  return (
    <PageSection id={id} cn={cn}>
      <Container>
        <SectionContent data={data} />
      </Container>
    </PageSection>
  );
};

export default ErrorSection;
