import { useAuth } from "contexts/AuthProvider";
import {
  dataSite,
  dataTopMenu,
  dataTopMenuMain,
  dataTopMenuSupport,
} from "data";
import { useEffect, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

//import ModalContact from "../sections/Home/ModalContact/ModalContact";

// http://themazine.com/html/Lebari/lebari/index.html

/**
 * Component for showing "Top menu" of the site.
 *
 * @component
 */
const TopMenu = () => {
  const [isSticky, setSticky] = useState(false);
  const { auth, setAuthData, isLogin, signIn, logout, register } = useAuth();

  const renderMenuItems = (menuItems) => {
    return menuItems.map((menuItem, index) => {
      if (!menuItem.path) {
        return null;
      }
      if (menuItem.showOnlyWhenLoggedIn && !isLogin) {
        return null;
      }

      return (
        <Nav.Item
          key={index}
          className={menuItem.showIcon ? "align-items-center" : ""}
        >
          {menuItem.showIcon && menuItem.icon}
          <Nav.Link
            key={menuItem.path || menuItem.title}
            href={menuItem.path}
            bsPrefix="top-menu-nav-link nav-link"
          >
            {menuItem.title}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };

  const renderSupportMenuItems = (menuItems) => {
    const renderDropDown = (menuItem, index) => {
      if (!menuItem.path) {
        return null;
      }
      if (menuItem.showOnlyWhenLoggedIn && !isLogin) {
        return null;
      }
      if (menuItem.isDivider) {
        return <NavDropdown.Divider />;
      }
      return (
        <NavDropdown.Item
          href={menuItem.path}
          key={index}
          bsPrefix="top-menu-nav-link nav-link-dropdown"
        >
          {menuItem.title}
        </NavDropdown.Item>
      );
    };

    return (
      <NavDropdown title="Поддержка" id="collapsible-nav-dropdown">
        {menuItems.map((menuItem, index) => {
          return renderDropDown(menuItem, index);
        })}
      </NavDropdown>
    );
  };

  const handleScroll = () => {
    const show = window.scrollY >= 200;

    if (show) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="top-menu"
      className={isSticky ? "header navbar-area sticky" : "header navbar-area"}
    >
      <Container>
        <div className="row align-items-center">
          <div className="col-lg-12">
            <Navbar expand="lg" className="">
              <Navbar.Brand href="/" className="">
                <img
                  alt="Электронный архив для организаций - ElArch"
                  src={require("assets/icons/logo-64x64.png")}
                  width="30"
                  height="30"
                  className="d-inline-block align-top top-menu-navbar-icon"
                />
                <span className="top-menu-navbar-title">
                  {dataSite.main.title}
                </span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="top-menu-navbar-nav" />
              <Navbar.Collapse
                id="navbar-collapse"
                style={{ justifyContent: "flex-end" }}
                className="top-menu-navbar-bar"
              >
                <Nav className="d-none d-xl-flex me-auto">
                  {renderMenuItems(dataTopMenuMain)}
                </Nav>
                <Nav className="">{renderMenuItems(dataTopMenu)}</Nav>
                <Nav className="">
                  {renderSupportMenuItems(dataTopMenuSupport)}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default TopMenu;

/*
<div>
                    <Nav.Item>
                      <div
                        class="tn-atom"
                        style={{ display: "inline", lineHeight: "25px" }}
                      >
                        <BiPhone />
                        <Nav.Link href="tel:+78005504662">
                          8 (800) 550-46-62
                        </Nav.Link>
                      </div>
                    </Nav.Item>
                  </div>
                  <div>
                    <Nav.Item>
                      <div class="tn-atom" style={{ lineHeight: "25px" }}>
                        <BiSupport />
                        <Nav.Link href="mailto:info@elarch.ru">
                          info@elarch.ru
                        </Nav.Link>
                      </div>
                    </Nav.Item>
                  </div>
*/
