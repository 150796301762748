import {
  InputFloatingLabelEmail,
  InputFloatingLabelMessage,
  InputFloatingLabelName,
  InputFloatingLabelPhone,
  InputFloatingLabelSubject,
} from "components/utils/Forms";

import { dataForm } from "data";
//import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { _debug } from "utils";

const msgSubmitted = "Сообщение успешно отправлено";
const msgSubmit = "Отправить сообщение";

/**
 * Component for showing Form "Contact" of the site.
 *
 * @component
 */
const FormContact = (props) => {
  const {
    methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  //const gaEventTracker = useAnalyticsEventTracker("User contact had send");

  // const [username, setUsername] = useState("User1");
  // const [email, setEmail] = useState("user1@local.loc");
  // const [message, setMessage] = useState("");
  //const [validate, setValidate] = useState({});

  const onSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);

    setError(null);
    try {
      //gaEventTracker("submitFromCountDown");

      const response = await props.onSubmit({
        email: data.email,
      });

      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
        return;
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    } finally {
      reset();
    }
  };

  return (
    <div className="contact-form-wrapper">
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
          method="POST"
          className="contact-form"
        >
          <Row className="g-3 mb-3">
            <Form.Group controlId="formContactName" as={Col} mb="6">
              <InputFloatingLabelName
                label={dataForm.name.msgLebel}
                placeholder={dataForm.name.msgPlaceholder}
                required={dataForm.name.msgRequired}
                register={register}
                errors={errors}
              />
            </Form.Group>
            <Form.Group controlId="formContactEmail" as={Col} mb="6">
              <InputFloatingLabelEmail
                label={dataForm.email.msgLebel}
                placeholder={dataForm.email.msgPlaceholder}
                required={dataForm.email.msgRequired}
                register={register}
                errors={errors}
              />
            </Form.Group>
          </Row>
          <Row className="g-3 mb-3">
            <Form.Group controlId="formContactPhone" as={Col} mb="6">
              <InputFloatingLabelPhone
                label={dataForm.phone.msgLebel}
                placeholder={dataForm.phone.msgPlaceholder}
                required={dataForm.phone.msgRequired}
                register={register}
                control={control}
                errors={errors}
              />
            </Form.Group>
            <Form.Group controlId="formContactSubject" as={Col} mb="6">
              <InputFloatingLabelSubject
                label={dataForm.subject.msgLebel}
                placeholder={dataForm.subject.msgPlaceholder}
                required={dataForm.subject.msgRequired}
                register={register}
                errors={errors}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="formContactMessage" as={Col}>
              <InputFloatingLabelMessage
                label={dataForm.message.msgLebel}
                placeholder={dataForm.message.msgPlaceholder}
                required={dataForm.message.msgRequired}
                register={register}
                errors={errors}
              />
            </Form.Group>
          </Row>
          <div className="text-center">
            <button type="submit" className="btn btn-primary theme-btn mx-auto">
              {msgSubmit}
            </button>
          </div>
        </Form>
        {error && (
          <p className="text-warning" style={{ textAlign: "center" }}>
            <small>{error}</small>
          </p>
        )}
        {success && (
          <p className="text-success" style={{ textAlign: "center" }}>
            <small>{msgSubmitted}</small>
          </p>
        )}
      </FormProvider>
    </div>
  );
};

export default FormContact;

/*

  const validateMessage = () => {
    let isValid = true;

    let validator = FormValidator.validator({
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    const validate = validateMessage();

    if (validate) {
    }

    setValidate({});
    setEmail("");
    setUsername("");
    setMessage("");
  };

<Row>
          <Col>
            <Form.Group controlId="form.username" className="form-group">
              <Form.Label className="sr-only">{msgUsernameLable}</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder={msgUsernamePH}
                className={`${
                  validate.validate && validate.validate.username
                    ? "is-invalid "
                    : ""
                }`}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Form.Text
                id="UsernameHelpBlock"
                muted
                hidden={!(validate.validate && validate.validate.username)}
              >
                Your username must contain letters and numbers, and must not
                contain spaces, special characters, or emoji.
              </Form.Text>
              <div
                className={`invalid-feedback text-start ${
                  validate.validate && validate.validate.username
                    ? "d-block"
                    : "d-none"
                }`}
              >
                {validate.validate && validate.validate.username
                  ? validate.validate.username[0]
                  : ""}
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="form.email" className="form-group">
              <Form.Label className="sr-only">{msgMessageLabel}</Form.Label>
              <Form.Control
                type="email"
                required
                placeholder={msgEmailPH}
                className={`${
                  validate.validate && validate.validate.email
                    ? "is-invalid "
                    : ""
                }`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text
                id="emailHelpBlock"
                muted
                hidden={!(validate.validate && validate.validate.email)}
              >
                Your email must contain letters and numbers, and must not contain
                spaces, special characters, or emoji.
              </Form.Text>
              <div
                className={`invalid-feedback text-start ${
                  validate.validate && validate.validate.email
                    ? "d-block"
                    : "d-none"
                }`}
              >
                {validate.validate && validate.validate.email
                  ? validate.validate.email[0]
                  : ""}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
          <InputGroupMessage label={msgMessageLabel} 
          placeholder={msgMessagePlaceholder}required, register, errors
            <Form.Group className="mb-3" controlId="form.message">
              <Form.Label className="sr-only">{msgMessageLabel}</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder={msgMessagePlaceholder}
              />
              <Form.Text
                id="messageHelpBlock"
                muted
                hidden={!(validate.validate && validate.validate.message)}
              >
                Your email must contain letters and numbers, and must not contain
                spaces, special characters, or emoji.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <div className="row">
          <div className="col-12">
            <div className="button text-center">
              <Button
                type="submit"
                variant="primary"
                className="btn btn-primary theme-btn mx-auto"
              >
                {msgButtonSubmit}
              </Button>
            </div>
          </div>
        </div>
        */
