import { useAuth } from "contexts/AuthProvider";
import { Tab, Tabs } from "react-bootstrap";
import { useDeviceData, useDeviceSelectors } from "react-device-detect";
import { PageSection } from "../Home";
import Upload from "../Profile/Upload";
import AddPost from "./AddPost";
import AppTable from "./AppTable";

// NOTE :

const AdminPage = (props) => {
  const { auth, setAuthData, isLogin, signIn, logout, register } = useAuth();
  const { id = "profile", cn = "pt-140" } = props;

  const deviceData = useDeviceData();
  //console.log(JSON.stringify(deviceData));
  const [selectors, deviceSelectorsData] = useDeviceSelectors(
    window.navigator.userAgent
  );
  //console.log(JSON.stringify(deviceSelectorsData));

  console.log(auth);

  const data = {
    title: "1",
    subtitle: "2",
    description: "3",
  };

  return (
    <main className="page-profile">
      <PageSection id={id} cn={cn}>
        <div className="container">
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Home">
              <div className="row">
                <AppTable />
              </div>
            </Tab>
            <Tab eventKey="uploads" title="Uploads">
              <PageSection id={"upload"} cn={"pt-140 pb-100"}>
                <div className="container">
                  <Upload />
                </div>
              </PageSection>
            </Tab>
            <Tab eventKey="content" title="Content">
              <PageSection id={"add_post"} cn={"pt-80 pb-100"}>
                <div className="container">
                  {auth.data == null ? (
                    <h3>Err</h3>
                  ) : (
                    <AddPost authData={auth} />
                  )}
                </div>
              </PageSection>
            </Tab>
            <Tab eventKey="contact" title="Contact" disabled>
              Tab content for Contact
            </Tab>
          </Tabs>
        </div>
      </PageSection>
    </main>
  );
};

export default AdminPage;
