import { PropTypes } from "prop-types";
import { PageSection, SectionHeader } from "./CommonSection";

const SectionContent = (props) => {
  const SectionItemEven = (props) => {
    const { data } = props;
    return (
      <div className="col-12">
        <div className="single-timeline">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="timeline-img">
                <img src={data.image} alt={data.imageAlt} />
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5">
              <div className="timeline-content right-content text-left">
                <div className="box-icon">{data.icon}</div>
                <h4 className="mb-10">{data.title}</h4>
                <p>{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SectionItemOdd = (props) => {
    const { data } = props;
    return (
      <div className="col-12">
        <div className="single-timeline">
          <div className="row align-items-center">
            <div className="col-lg-5 order-last order-lg-first">
              <div className="timeline-content left-content text-lg-right">
                <div className="box-icon">{data.icon}</div>
                <h4 className="mb-10">{data.title}</h4>
                <p>{data.description}</p>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5">
              <div className="timeline-img">
                <img src={data.image} alt={data.imageAlt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const { data } = props;
  return (
    <div className="row align-items-center time-line">
      {data.items.map((item, index) => {
        if (index % 2 !== 0) return <SectionItemEven data={item} key={index} />;
        return <SectionItemOdd data={item} key={index} />;
      })}
    </div>
  );
};

/**
 * Component for showing Section "Process" of the site.
 *
 * @component
 */
const Process = (props) => {
  const { data, id = "process", cn = "pt-130 pb-80" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

Process.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Process;
