import axios from "axios";
import { API_URI } from "config/config";
import { Fragment, useState } from "react";
import { Button, Col, Form, ProgressBar, Row } from "react-bootstrap";

const Upload = () => {
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const token = "";
  const URL = `${API_URI}/uploadFiles`;
  const headers = { Authorization: `Bearer ${token}` };

  const handleChange = (event) => {
    setFiles([...event.target.files]);
  };

  const handleMultipleSubmit = (event) => {
    event.preventDefault();
    const url = URL;
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        ...headers,
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        setUploadedFiles(response.data.files);
      })
      .catch((error) => {
        console.error("Error uploading files: ", error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = URL;
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        ...headers,
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error uploading file: ", error);
      });
  };
  /*
      {file && (
        <section>
          File details:
          <ul>
            <li>Name: {file.name}</li>
            <li>Type: {file.type}</li>
            <li>Size: {file.size} bytes</li>
          </ul>
        </section>
      )}
      */
  return (
    <Fragment>
      <Row>
        <Col>
          <div className="upload-form-wrapper">
            <Form onSubmit={handleMultipleSubmit}>
              <h2>Загрука в архив</h2>
              <input type="file" onChange={handleChange} multiple />
              <Button variant="primary" type="submit">
                Upload
              </Button>
              <ProgressBar value={uploadProgress} max="100"></ProgressBar>
            </Form>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {uploadedFiles.map((file, index) => (
            <img key={index} src={file} alt={`Uploaded content ${index}`} />
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};

export default Upload;
