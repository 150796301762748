import { PropTypes } from "prop-types";
import { _debug } from "utils";
import { PageSection, SectionHeader } from "./CommonSection";

const SectionItemStyle1 = (props) => {
  const { data, index } = props;
  return (
    <div className="col-lg-4 col-md-6">
      <div className="feature-box box-style">
        <div className="feature-icon box-icon">{data.icon}</div>
        <div className="feature-content box-content">
          <h3 className="feature-title box-title">{data.title}</h3>
          <p className="feature-desc box-desc">{data.description}</p>
        </div>
      </div>
    </div>
  );
};

const SectionItemStyle2 = (props) => {
  const { data, index } = props;
  return (
    <div className="col-xl-3 col-lg-3 col-sm-6">
      <div className="feature-box box-style2">
        {data.icon ? (
          <div className="feature-icon box-icon">{data.icon}</div>
        ) : (
          <div className="feature-image-wrapper box-image-wrapper">
            <img
              className="box-image img-fluid"
              src={data.image}
              alt={data.imageAlt}
            />
          </div>
        )}

        <div className="feature-content box-content">
          <h3 className="feature-title box-title">{data.title}</h3>
          <p className="feature-desc box-desc">{data.description}</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, type } = props;
  const items = data.items.slice(0, type === "style1" ? 3 : 4);

  if (
    (type === "style1" && data.items.lenght !== 3) ||
    (type === "style2" && data.items.lenght !== 4)
  ) {
    _debug("Wrong data items sets!");
  }

  return (
    <div className="row equal">
      {items &&
        items.map((item, index) => {
          if (type === "style1")
            return <SectionItemStyle1 data={item} index={index} key={index} />;
          return <SectionItemStyle2 data={item} index={index} key={index} />;
        })}
    </div>
  );
};

/**
 * Component for showing Section "Feature" of the site.
 *
 * @component
 */
const Feature = (props) => {
  const { data, id = "feature2", cn = "pt-130 pb-80", type } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} type={type} />
      </div>
    </PageSection>
  );
};

Feature.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
};

Feature.defaultProp = {
  type: "style1",
};

export default Feature;
