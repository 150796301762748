import { FaGift } from "react-icons/fa6";
import { GoCodeReview, GoRocket } from "react-icons/go";
import { LuLayers, LuSearch } from "react-icons/lu";

/**
 * Data of page "Product - Archive" of the site.
 *
 *
 */
export const dataHeroProductPage = {
  title: "Электронный архив АИС «Архив - ELARCH»",
  subtitle: "Внедрение информационных системы",
  description:
    "Автоматизация основных направлений деятельности архивов организаций, государственных и муниципальных архивов на базе отечественной платформы АИС «Архив - ELARCH»",
  items: [],
  image: require("assets/images/hero/hero-img (1).webp"),
  imageAlt: "АИС «Архив - ELARCH» - сервис электронного архива",
};

export const dataFeatureProductPage = {
  title: "Для чего использовать?",
  subtitle: "Для чего использовать?",
  description:
    "At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
  items: [
    {
      title: "Финансовые и бухгалтерские документы",
      subtitle: "",
      description:
        "Счета, счета-фактуры, акты, товарные накладные, платежные и другие виды первичных документов.",
      icon: <FaGift />,
      //image: Image1,
      image: null,
      imageAlt: "",
    },
    {
      title: "Договоры и юридические документы",
      subtitle: "",
      description:
        "Договоры, дела, судебные и иные решения, приказы, постановления, нотариально заверенные документы",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Научных и проектных документов",
      subtitle: "",
      description:
        "Научные и проектные документы, чертежи, карт, полиграфической продукции, документов в плохом состоянии",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Универсальный электронный архив",
      subtitle: "",
      description:
        "Хранение электронных копий документов бумажного архива, оцифрованная документация организации",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataOverviewProductPage = {
  title: "Возможности архива",
  subtitle: "Возможности архива",
  description:
    "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
  items: [
    {
      title: "Храните неограниченный объем файлов",
      subtitle: "",
      description1:
        "Система использует эффективную распределенную архитектуру, что позволяет масштабировать процессы в неограниченных объемах. Система размещена в облачной конфигурации в датацентре на территории России что позволяет полностью соответствовать законодательству Российской Федерации.",
      description2: "",
      icon: null,
      image: require("assets/images/feature/features2-1_600x600.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description: "",
          },
          {
            description: "",
          },
          {
            description: "",
          },
        ],
      },
    },
    {
      title: "Потоковая загрузка файлов из различных источнико",
      subtitle: "",
      description1:
        "Система предоставляет различные предустановленные варианты загрузки данных, включая использование встроенной станции сканирования для потоковой конвертации бумажных документов в электронные.",
      description2: "",
      icon: null,
      image: require("assets/images/feature/features2-2_600x600.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description: "",
          },
          {
            description: "",
          },
          {
            description: "",
          },
        ],
      },
    },
    {
      title:
        "Большое количество интеграционных коннекторов к различным источникам данных",
      subtitle: "",
      description:
        "Файловые коннекторы; Коннекторы к различным учетным системам 1С, SAP; К API операторов юзЭДО.",
      icon: null,
      image: require("assets/images/feature/features2-4_600x600.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description: "",
          },
          {
            description: "",
          },
          {
            description: "",
          },
        ],
      },
    },
    {
      title: "Доступ к просмотру файлов из любой точки мира",
      subtitle: "",
      description:
        "Нет необходимости устанавливать какое-либо программное обеспечение на рабочие станции пользователей. Удобный функционал работы через web и мобильные приложения.",
      icon: null,
      image: require("assets/images/feature/features2-7_600x600.webp"),
      imageAlt: "",
      list: {
        items: [
          {
            description: "",
          },
          {
            description: "",
          },
          {
            description: "",
          },
        ],
      },
    },
  ],
};

export const dataArchSection = {
  title: "Электронный архив документов",
  subtitle: "Цифровая трансформация бизнеса",
  description:
    "Организуйте архивное хранение электронных документов с помощью нашего облачного сервиса. " +
    "Наш сервис поможет организовать архивное хранение документов с учетом их типа и необходимых параметров для хранения.",
  image: require("assets/images/about-img.webp"),
  imageAlt: "",
  items: [],
};

export const dataTestimonial = {
  title: "Что говорят наши клиенты?",
  subtitle: "Отзывы",
  description: "",
  items: [
    {
      title: "Лукьянов Андрей",
      subtitle: "Коммерческий директор ООО «АСТРА ТЕХНОЛОГИИ» ",
      description:
        "Для нашей задачи создания электронной библиотеки организации ELARCH подошел почти идеально. Хорошо, что продукт постоянно прогрессирует и радует новыми возможностями.",
      image: require("assets/images/testimonial/testimonial-1-150x150.webp"),
    },
    {
      title: "Ломакин Александр",
      subtitle: "Директор ООО “Скан Солюшн”",
      description:
        "ELARCH оказался для наших проектов по организации удалённой обработки большого количества документов единственным подходящим решением. Подобного решения раньше на рынке просто не было.",
      image: require("assets/images/testimonial/testimonial-2-150x150.webp"),
    },
  ],
};

export const dataProcessProductPage = {
  title: "Рабочий процесс",
  subtitle: "Процесс",
  description:
    "At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
  items: [
    {
      title: "Загрузка и регистрация документов",
      subtitle: "",
      description:
        "Настройка интерации архива с системами-источниками. Выполнение контроля полноты и достоверности данных передаваемых в архив.",
      image: require("assets/images/timeline/timeline-1.webp"),
      imageAlt: "",
      icon: <LuSearch />,
    },
    {
      title: "Комплектование архива",
      subtitle: "",
      description:
        "Быстрый и удобный поиск документов в архиве. Связь между документами. Группировка документов в архиве. Электронная подпись документов в архиве.",
      image: require("assets/images/timeline/timeline-2.webp"),
      imageAlt: "",
      icon: <LuLayers />,
    },
    {
      title: "Управление документами в архиве",
      subtitle: "",
      description:
        "Хранение документов в архиве. Прием на хранение, перемещения и уничтожение. Запросы к архиву. Уничтожение дел. ",
      image: require("assets/images/timeline/timeline-3.webp"),
      imageAlt: "",
      icon: <GoCodeReview />,
    },
    {
      title: "Контроль полноты и достоверности",
      subtitle: "",
      description: "Описи и отчеты. Опись единиц хранения.",
      image: require("assets/images/timeline/timeline-4.webp"),
      imageAlt: "",
      icon: <GoRocket />,
    },
  ],
};

// NOTE : https://atlas-soft.ru/products/atlas-archive/

export const dataFeatureTabsProductPage = {
  title: "Возможности сервиса",
  subtitle: "Преимущества электронного архива",
  description: "Возможности",

  items: [
    {
      key: "key1",
      title: "Преимущества",
      subtitle: "Ключевые преимущества",
      description:
        "Используйте фукции объектных хранилищ данных, возможности электронного облачного файлового хранилища для создания онлайн электронного архива и совместной работы с данными и файлами.",
      image: require("assets/images/feature/feature1.webp"),
      imageAlt: "",
      items: [
        {
          description:
            "Можно использовать как для ведения архивного дела, так и в качестве основного средства автоматизации работы со всеми документами организации и создания централизованного хранилища",
        },
        {
          description:
            "Высокая производительность благодаря непрерывному поиску уникальных технологических решений и совершенствованию системы",
        },
        {
          description:
            "Сокращение издержек за счет экономии офисных площадей за счет перехода на централизованное, надёжное и компактное электронное хранилище",
        },
      ],
    },
    {
      key: "key2",
      title: "Простота",
      subtitle: "Простота использования",
      description:
        "Встроенная система распознавания, с помощью гибко настраиваемых правил, позволяет индексировать загруженные файлы, для более удобного последующего поиска объектов. Все процессы происходят автоматически в фоне, не требуя дополнительных действий пользователя.",
      image: require("assets/images/feature/feature2.webp"),
      imageAlt: "",
      items: [
        {
          description:
            "Быстро находите нужную информацию используя различные варианты поиска информации: поиск по струтктуре данных, поиск по метадынным, поиск по значениям полей, поиск внутри файлов.",
        },
        {
          description:
            "Используйте привычную иерархическую структуру для группировки данных и управления доступом пользователей к разделам структуры. Быстрый поиск, настраиваемый цвет иконок, drag & drop, групповые операции, упростят работу с данными",
        },
        {
          description:
            "Используйте пользовательские справочники, автозаполнение, фикированные значения полей и горячие клавишы для ускорения ввода данных.",
        },
      ],
    },
    {
      key: "key3",
      title: "Интеграция",
      subtitle: "Широкие возможности интеграция",
      description:
        "Быстрая интеграция с любыми сторонними информационными системами для создания единого информационного пространства и долговременного хранения",
      image: require("assets/images/feature/feature3.webp"),
      imageAlt: "",
      items: [
        {
          description:
            "Готовые коннекторы (модули) для интеграции с самыми распространенными системами: 1С и другие",
        },
        {
          description:
            "Загружайте и выгружайте имеющиеся данные и файлы в любое время и в нужном вам виде. Используйте настраиваемый экспорт в файлы форматов XLS и CSV, групповую выгрузку/загрузку файлов с сохранением структуры папок, частичный или полный экспорт как данных так и файлов.",
        },
        {
          description:
            "Удобный API-интерфейс для нтегрирации с любыми учетными системами",
        },
      ],
    },
    {
      key: "key4",
      title: "Безопасность",
      subtitle: "Сохранность и безопасность",
      description:
        "Держите всё под контролем. Настраивайте отсылку сообщений при возникновении событий безопасности. Система ролей с помощью гибко настраиваемых правил, позволяет настроить требуемый уровень безопасности. Все процессы регистрации действий происходят автоматически в фоне, не требуя дополнительных действий пользователя.",
      image: require("assets/images/feature/feature4.webp"),
      imageAlt: "",
      items: [
        {
          description:
            "Работа с юридически значимыми электронными цифровыми подписями по стандарту ГОСТ Р 34.10-2001, защита информацию средствами, прошедшими сертификацию FIPS.",
        },
        {
          description:
            "Просматривайте историю изменения записей и всегда будьте в курсе кто и когда изменял данные и какие значенния были изменены.",
        },
        {
          description:
            "Высокий уровень безопасности благодаря гибким настройкам доступов и отслеживаются все изменения и перемещения документов исключает риск несанкционированного доступа.",
        },
        {
          description:
            "Облачный или Локальный архив - возможность работы как в облачной модели (подходит для быстрого старта), так и внедрение локального объектного хранилища (подходит корпоративным Заказчикам, поддержка Docker и Kubernets).",
        },
      ],
    },
  ],
};
