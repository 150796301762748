import DOMPurify from "isomorphic-dompurify";
import { Fragment } from "react";
import { _debug } from "utils";

const msgTitle = "Подробнее";
const msgClose = "Закрыть";

const FormAdditionalInfo = (props) => {
  const { data, title, onSubmit, handleClose } = props;

  _debug(data);

  return (
    <Fragment>
      <div className="modal-content">
        <div className="modal-header mb-3">
          <h3 id="additional-info-form-modal-title">{title || msgTitle}</h3>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          />
        </div>
        <div className="modal-body site-content">
          {data.description && (
            <div className="col-md-10">
              <p className="fst-italic">{data.description}</p>
            </div>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.descriptionHTML),
            }}
          ></div>
        </div>
        <div className="modal-footer">
          <button
            onClick={handleClose}
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {msgClose}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default FormAdditionalInfo;
