export const dataTeam = {
  title: "Наша команда",
  subtitle: "Узнайте о нас",
  description: "Наша команда",
  items: [
    {
      index: "1",
      name: "Александр Ловков",
      title: "Founder, Chief Executive Officer",
      description:
        "Aliquam iure quaerat voluptatem praesentium possimus under laudantium vel dolorum distinctio dire flow",
      image: require("assets/images/team/team-2.webp"),
      imageAlt: "",
      socials: [
        { type: "tg", link: "https://t.me/ddampty/", name: "Telegram" },
        {
          type: "ln",
          link: "https://www.linkedin.com/in/lovkovav/",
          name: "LinkedIn",
        },
        {
          type: "fb",
          link: "https://www.facebook.com/lovkovav/",
          name: "Facebook",
        },
        {
          type: "in",
          link: "https://www.instagram.com/ddampty/",
          name: "Instagram",
        },
      ],
      aosDelay: "100",
    },
    {
      index: "2",
      name: "Василий Дудоров",
      title: "CIO/СТО, Co-founder",
      description:
        "Magni voluptatem accusamus assumenda cum nisi aut qui dolorem voluptate sed et veniam quasi quam consectetur",
      image: require("assets/images/team/team-4.webp"),
      socials: [
        { type: "tg", link: "https://t.me/DudorovVasilii", name: "Telegram" },
        {
          type: "ln",
          link: "https://www.linkedin.com/in/vasilii-dudorov/",
          name: "LinkedIn",
        },
        {
          type: "fb",
          link: "https://www.facebook.com/vasiliy.dudorov/",
          name: "Facebook",
        },
      ],
      aosDelay: "400",
    },
    {
      index: "3",
      name: "Мирина Пали",
      title: "CSO, Sales",
      description:
        "Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit",
      image: require("assets/images/team/team-5.webp"),
      socials: [
        { type: "tg", link: "https://t.me/MarinaPali" },
        {
          type: "ln",
          link: "https://www.linkedin.com/in/marina-pali-44a0b721/",
          name: "LinkedIn",
        },
      ],
      aosDelay: "500",
    },
    {
      index: "4",
      name: "Евгений Абрамов",
      title: "Front-end developer",
      description:
        "Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel",
      image: require("assets/images/team/team-6.webp"),
      socials: [
        { type: "fb", link: "/", name: "Facebook" },
        { type: "in", link: "/", name: "LinkedIn" },
      ],
      aosDelay: "600",
    },
  ],
};
