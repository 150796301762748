import { FloatingLabel, Form } from "react-bootstrap";
import InputGroupError from "./InputGroupError";

export const InputFloatingLabelName = (props) => {
  const { label, placeholder, required, register, errors, ...otherProps } =
    props;

  return (
    <FloatingLabel>
      <Form.Control
        type="text"
        name="name"
        placeholder={placeholder}
        isInvalid={!!errors.name}
        {...register("name", {
          required: required,
          pattern: /^[a-zA-Z]+$/,
          validate: {
            maxLength: (v) =>
              v.length <= 25 || "The name should have not more 25 characters",
            minLength: (v) => v.length > 3 || "The name should be entered",
          },
        })}
        {...otherProps}
        aria-invalid={errors?.name ? "true" : "false"}
        aria-describedby="nameHelpBlock"
      />
      <Form.Label>{label || "Name"}</Form.Label>
      {errors.name && (
        <Form.Control.Feedback type="invalid">
          <InputGroupError message={errors.name.message} />
        </Form.Control.Feedback>
      )}
    </FloatingLabel>
  );
};

const InputGroupUsername = (props) => {
  const {
    label,
    placeholder,
    help,
    required,
    register,
    errors,
    id,
    ...otherProps
  } = props;

  return (
    <Form.Group controlId={id || "name"}>
      <InputFloatingLabelName
        label={label}
        placeholder={placeholder}
        required={required}
        register={register}
        errors={errors}
        {...otherProps}
      />
      {help && (
        <Form.Text
          id="nameHelpBlock"
          muted
          //hidden={!(errors.name && errors.name.message)}
        >
          {help}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default InputGroupUsername;
