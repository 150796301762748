import axios from "axios";
import { API_URI } from "config/config";
import { useState } from "react";
import { Button, Col, Form, ProgressBar, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

const Result = ({ status }) => {
  if (status === "success") {
    return <p>✅ File uploaded successfully!</p>;
  } else if (status === "fail") {
    return <p>❌ File upload failed!</p>;
  } else if (status === "uploading") {
    return <p>⏳ Uploading selected file...</p>;
  }
  return null;
};

const AddPost = (props) => {
  const { authData } = props;
  //const { data } = authData;
  const { accessToken: token } = authData.data;

  console.log(authData);

  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [imagePath, setImagePath] = useState(null);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);

  const [status, setStatus] = useState("initial"); // <"initial" | "uploading" | "success" | "fail">

  const initState = {
    title: "New Post 1",
    category: 2,
    tags: "tag1, tag2, tag3",
    description: "Short description about my new Post",
    file: null,
    image: null,
  };

  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = useState(initState);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initialValues,
  });

  //const token = "";
  const URL = `${API_URI}/posts/v2`;
  const headers = {
    Accept: "application/json",
    //"Content-Type": "application/json",
    "Content-Typee": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const handleOnSubmit = (values) => {
    console.log("Values:::", values);
    console.log("Values:::", JSON.stringify(values));

    setStatus("initial");

    //event.preventDefault();
    if (values.files) {
      setFile(values.files[0]);
    }
    if (values.images) {
      setImage(values.images[0]);
    }

    var tags = values?.tags.split(",").map((el) => el.trim());

    console.log(tags);

    if (file && image) {
      setStatus("uploading");

      const formData = new FormData();
      formData.append(`file`, file);
      formData.append(`image`, image);
      formData.append(
        `data`,
        JSON.stringify({
          title: values.title,
          description: values.description,
          category: { id: values.category },
          tags: tags,
        })
      );

      const config = {
        headers: {
          ...headers,
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      };

      console.log(config);

      axios
        .post(URL, formData, config)
        .then((response) => {
          setStatus("success");
          console.log(response.data);
        })
        .catch((error) => {
          setStatus("fail");
          console.error("Error uploading file: ", error);
        });
    }
  };
  const handleOnError = (error) => {
    console.error("ERROR:::", error);
  };

  return (
    <Row>
      <Col>
        <div className="upload-form-wrapper">
          <Form onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
            <h2>Новый пост</h2>

            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Post title"
                {...register("title", { required: "Title is requered" })}
              />
              {errors.title && (
                <Form.Text className="text-danger">
                  {errors.title.message}
                </Form.Text>
              )}
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formCategory">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Category"
                    {...register("category", {
                      required: "Category is requered",
                    })}
                  />
                  {errors.category && (
                    <Form.Text className="text-danger">
                      {errors.category.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formTags">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="tag1, tag2, ..."
                    {...register("tags", {
                      required: "Tags is requered",
                    })}
                  />
                  {errors.tags && (
                    <Form.Text className="text-danger">
                      {errors.tags.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register("description", {
                  required: "Description is requered",
                })}
              />
              {errors.description && (
                <Form.Text className="text-danger">
                  {errors.description.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Large file input example</Form.Label>
              <Form.Control
                as="input"
                type="file"
                size="lg"
                {...register("files", {
                  required: "Content file is required",
                })}
              />
              {errors.file && (
                <Form.Text className="text-danger">
                  {errors.file.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formImage" className="mb-3">
              <Form.Label>Image file input example</Form.Label>
              <Form.Control
                as="input"
                type="file"
                size="lg"
                {...register("images", {
                  required: "Image file is required",
                })}
              />
              {errors.image && (
                <Form.Text className="text-danger">
                  {errors.image.message}
                </Form.Text>
              )}
            </Form.Group>

            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </div>
        <ProgressBar
          striped
          variant="info"
          now={uploadProgress}
          max="100"
        ></ProgressBar>
        <Result status={status} />
      </Col>
    </Row>
  );
};

export default AddPost;
