import { Spinner } from "components/utils/Spinner";
import { API_URI, BLOG_ID } from "config/config";
import { useFetch } from "hooks";
import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { _debug } from "utils";

const BlogTopTags = () => {
  const URL = `${API_URI}/tags/all?blog=${BLOG_ID}`;
  const { data: tagsData, isLoading, hasError, errorMessage } = useFetch(URL);
  const tagsDataTop = tagsData ? tagsData.slice(0, 3) : undefined;

  _debug(URL);

  useEffect(() => {
    if (tagsData) _debug("Data fetched: ", tagsData);
    if (hasError) _debug("Error: ", errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasError, tagsData]);

  if (isLoading || hasError) {
    return (
      <Fragment>
        {isLoading && <Spinner />}
        {hasError && <p>{errorMessage}</p>}
      </Fragment>
    );
  }

  if (!tagsData) {
    return <h5>No tags founds</h5>;
  }

  return (
    <div className="sidebar-box tags-box mb-30">
      <h4>Популярные тэги</h4>
      <ul>
        {tagsDataTop.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`/blog/tag/${item.id}?blog=${BLOG_ID}`}>
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BlogTopTags;
