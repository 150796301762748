export const dataMetaHomePage = {
  title:
    "Электронный архив: организация архива документов организации и предприятий - ELARCH",
  //  Cоздание, обработка и организация электронного архива документов предприятия - ELARCH"
  // "Электронный архив – ELARCH",
  //  Система электронного архива "ELARCH": цифровое хранение документов
  description:
    "Cпециализированные решения по созданию, комплексному оснащению и наполнению электронных архивов, внедрению передовых информационных технологий и решений в области электронного документооборота. Попробуйте бесплатно и оцените удобство и простату наших решений.",
  // "ELARCH автоматически архивирует все бухгалтерские документы с учетом типа документа, регистра и параметров и делает их хранение надежным и удобным. Загрузите 10 страниц бесплатно и оцените удобство",
  // Электронный архив: создание и внедрение системы для организации и хранения ваших документов в цифровом виде. &quot;ELARCH&quot;, звоните: +7 (925) 995-87-90!
  // Электронный архив документов - создание единого электронного архива для всех типов документов - бумажных и электронных. Интеграция электронного архива в любую учётную систему - услуги Инфолоджистикс.
  // High Performance Object Storage, Multi-Cloud, Hybrid Cloud, Object Storage, Object Storage for AI, Cloud Storage, S3 Compatible, Golang, AWS S3, Amazon S3, Open Source Cloud Storage, Ceph, OpenStack Swift, Microsoft Azure Blobstore, Google Cloud Storage, Cloud Foundry Blobstore, Cloud Foundry Bosh, Artifact Storage, DC/OS, Kubernetes, Docker, Container Storage, Blobstore, NAS, Gluster, GlusterFS, PACS, VNA, Archive, Secondary Storage, Backup Storage, Snapshots Hybrid, OnPrem, Private Cloud
  // <meta name="description" content="MinIO's High Performance Object Storage is Open Source, Amazon S3 compatible, Kubernetes Native and is designed for cloud native workloads like AI.">
  name: "",
  type: "",
  author: "ELARCH, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content:
        "Система электронного архива &quot;ELARCH&quot;: цифровое хранение документов",
      //Облачный сервис Entera автоматически архивирует все отсканированные документы с учетом типа документа, регистра и параметров. Электронные копии документов будут доступны для экспорта на случай проверки или восстановления базы 1С. Копии автоматически “облегчаются” до размера, который полностью соответствует требованиям ФНС.
    },
    {
      property: "og:description",
      content:
        "Электронный архив: создание и внедрение системы для организации и хранения ваших документов в цифровом виде. &quot;ELARCH&quot;, звоните: +7 (925) 995-87-90!",
    },
    {
      property: "og:url",
      content: "https://elarch.ru/",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    { property: "og:image:alt", content: "ELARCH" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "ELARCH",
    },
  ],
  TW: [
    { name: "twitter:creator", content: "" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "" },
    { name: "twitter:description", content: "" },
    { name: "twitter:label1", content: "Примерное время для чтения" },
    { name: "twitter:data1", content: "10 минуты" },
  ],
};
