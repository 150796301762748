import { API_URI } from "config/config";
import { createContext, useContext, useEffect, useState } from "react";
import { _debug } from "utils";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    isLogin: false,
    error: false,
    message: "",
    data: null,
  });
  const [isLogin, setIsLogin] = useState(false);

  const setAuthData = (data) => {
    setAuth({ data: data });
  };

  useEffect(() => {
    var localData = window.localStorage.getItem("authData");

    _debug("Read local cache:", JSON.parse(localData));
    //if (!localData) {
    //  console.log(`object`);
    //  localData = {};
    //}
    setAuth({
      loading: false,
      error: false,
      message: "",
      data: JSON.parse(localData),
    });
  }, []);
  //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.
  //This function will be executed every time component is mounted (every time the user refresh the page);

  useEffect(() => {
    //console.log(`object1`, auth.data);
    window.localStorage.setItem(
      "authData",
      JSON.stringify(auth.data == null ? {} : auth.data)
    );
    //var localData = window.localStorage.getItem("authData");
    //console.log(localData);
  }, [auth.data]);

  // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.
  const customHeaders = {
    "Content-Type": "application/json",
  };

  const signIn = async (credentials) => {
    _debug("Let's login...", credentials);

    try {
      const response = await fetch(`${API_URI}/auth/login`, {
        method: "POST",
        headers: customHeaders,
        body: JSON.stringify({
          ...credentials,
          usernameOrEmail: credentials.email,
          deviceInfo: {
            deviceId: "12-3",
            deviceType: "DEVICE_TYPE_IOS",
            notificationToken: "",
          },
        }),
      });

      let result = await response.json();
      console.log(result);
      //let res = { ...result.data };

      setAuth({
        ...auth,
        isLogin: true,
        error: false,
        message: "",
        data: {
          accessToken: result?.accessToken,
          refreshToken: result?.refreshToken,
          email: result?.email,
          userId: result?.id,
        },
      });
      setIsLogin(true);
      //setAuthData();
      //setUser(userObj);
      //toastsuccess("Login Successfull");
      //navigate(location.state.from || "/");
    } catch (err) {
      console.error("Login error: ", err);
      setAuth({
        ...auth,
        isLogin: false,
        error: true,
        message: err,
      });
      //toasterror("Login Failed");
    }
  }; // make a login request
  const register = async (credentials) => {
    _debug("Let's register...", credentials);
    try {
      const response = await fetch(`${API_URI}/auth/register`, {
        method: "POST",
        headers: customHeaders,
        body: JSON.stringify({ ...credentials }),
      });

      let result = await response.json();
      console.log(result);

      //console.log(auth.data);
      //console.log(authresult);
      //let res = { ...authresult.data };
      //userObj.token = authresult.data?.accessToken;
      //setUser(userObj);
      //toastsuccess("Login Successfull");
      //navigate(location.state.from || "/");

      setAuth({ ...auth, error: false, message: "" });
    } catch (err) {
      console.error("Register error: ", err);
      setAuth({
        ...auth,
        error: true,
        message: err,
      });
      //toasterror("Login Failed");
    }
  }; // register the user
  const logout = () => {
    _debug("Let's logout...");
  }; // clear the token in localStorage and the user data

  // Memoized value of the authentication context
  /*const contextValue = useMemo(
      () => ({
        token,
        setToken,
      }),
      [token]
    );*/

  return (
    <authContext.Provider
      value={{ auth, setAuthData, isLogin, signIn, logout, register }}
    >
      {children}
    </authContext.Provider>
  );
};

const useAuth = () => {
  return useContext(authContext);
};

export { AuthProvider, useAuth };
