export const fakeBlogData = {
  title: "Recent Blog Post",
  subtitle: "Blog",
  description:
    "We Crafted an awesome design library that is robust aintuitive to use. No matter you're building a busine presentation websit or complex web application.",
  items: [
    {
      title: "Start a Business Guide",
      subtitle: "",
      description:
        "Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy eirmod tempor ividunt dolore magna.",
      postDate: "15 June, 2025",
      image: require("assets/images/blog/blog-1.webp"),
      imageAlt: "",
      animationDelay: "0.2s",
    },

    {
      title: "Plan for what is difficult",
      subtitle: "",
      description:
        "Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy eirmod tempor ividunt dolore magna.",
      postDate: "15 June, 2025",
      image: require("assets/images/blog/blog-2.webp"),
      imageAlt: "",
      animationDelay: "0.4s",
    },
    {
      title: "Colorful Easter Eggs",
      subtitle: "",
      description:
        "Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy eirmod tempor ividunt dolore magna.",
      postDate: "15 June, 2025",
      image: require("assets/images/blog/blog-3.webp"),
      imageAlt: "",
      animationDelay: "0.4s",
    },
  ],
};
