import DOMPurify from "isomorphic-dompurify";
import { PropTypes } from "prop-types";
import { PageSection, SectionContentHeader } from "../CommonSection";
import FormSubscription from "./Subscribe.form";

// NOTE : https://wpthemesgrid.com/themes/appmeet/index1.html

const msgSubscribed = "Вы успешно подписаны";
const msgFormEmailPlaceholder = "Ваша почта";
const msgFormEmailRequired = "Требуется ввести адрес электронной почты"; // "Email is required",

const SectionContent = (props) => {
  const { data } = props;

  return (
    <div className="row align-items-center">
      <div className="col-lg-6 col-md-12 col-12">
        <div className="image">
          <img src={require("assets/images/newsletter-img.webp")} alt="" />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-12">
        <div className="content section-content">
          <SectionContentHeader data={data} className="mb-30" />
          {data.componentHTML ? (
            <div
              className="mt-3"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.componentHTML.data),
              }}
            ></div>
          ) : (
            <p>{data.description}</p>
          )}
          <FormSubscription />
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Subscribe" of the site.
 *
 * @component
 */
const Subscribe2 = (props) => {
  const { data, id = "subscribe2", cn = "pt-70 pb-70 newsletter" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

Subscribe2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Subscribe2;
