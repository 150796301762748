import { BiCapsule } from "react-icons/bi";
import {
  TfiDashboard,
  TfiInfinite,
  TfiReload,
  TfiServer,
  TfiShortcode,
} from "react-icons/tfi";

/**
 * Data of page "Main" of the site.
 *
 *
 */

export const dataHeroMainPage = {
  title: "Электронный архив для организаций",
  subtitle: "Cервис долговременного хранения электронных документов",
  description:
    "Aрхивное хранение электронных документов и данных, систематизация и поиск, контроль доступа к контенту",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: [],
  image: require("assets/images/hero/hero2-920x820.webp"),
  imageAlt: "Сервис электронного архива документов организации - ELARCH",
};

export const dataOverviewMainPage = {
  title: "Готовые отраслевые решения",
  subtitle: "Готовые отраслевые решения",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "Готовые решения для быстрого внедрения в разных отраслях и сферах бизнеса, государственных и муниципальных организациях помогут повысить операционную эффективность и автоматизируют процессы электронного документооборота",
  items: [
    {
      title: "Финансовые и бухгалтерские документы",
      subtitle: "",
      description1:
        "Ораганизация хранения всех финансовых и бухгалтерских документов, отчетов и регистров",
      description2:
        "Счета, счета-фактуры, акты, товарные накладные, платежные и другие виды первичных документов",
      icon: null,
      image: require("assets/images/feature/features1-1_600x600.webp"),
      imageAlt: "Фото электронный архив финансовых и бухгалтерских документов",
      imageTitle: null,
      list: {
        items: [
          {
            description:
              "Хранение первичных документов поступивших от контрагентов в электронном/бумажном виде",
          },
          {
            description: "Архив электронных документов полученных по ЭДО",
          },
          {
            description:
              "Быстрый поиск и выгрузка документов по запросам проверяющих органов (ФНС)",
          },
          {
            description:
              "Возможность реализации функции налогового мониторинга",
          },
          { description: "Интеграция с 1С:Бухгалтерия и др." },
        ],
      },
      control: {
        show: true,
        msgSubmit: "Подробнее",
        title: "Архив финансовых и бухгалтерских документов",
        description: "",
        descriptionHTML:
          "<h4>Преимущества</h4>" +
          "<p>Система обеспечивает централизованный сбор, хранение и эффективное управление всей бухгалтерской информацией: документами ЭДО и скан-копиями бумажных документов.</p>" +
          "<p>Решение позволяет ускорить и автоматизировать процессы ввода и обработки документов. Благодаря модулям интеграции доступ к финансовым документам предоставляется непосредственно из ERP и других учетных систем компании.</p>" +
          "<p>Эффективное взаимодействие и безопасность бизнеса при проведении налоговых проверок за счет быстрого сбора консолидированной отчетности и любых подборок документов.</p>" +
          "<p>Современная архитектура и масштабируемость платформы позволяют оперативно дорабатывать и тиражировать решение, а также безболезненно увеличивать объемы, обрабатываемых финансовых документов.</p>",
      },
    },
    {
      title: "Документы кадрового и воинского учёта",
      subtitle: "",
      description1:
        "В соответствии с законодательством необходима организация хранения большого количества документов на каждого сотрудника, начиная с его трудоустройства, заканчивая увольнением",
      description2:
        "Кадровые Приказы, трудовые договора, договора ГПХ, должносные инструкции, любые документы воинского учета и карточки личных дел (Т2)",
      icon: null,
      image: require("assets/images/feature/features1-6_600x600.webp"),
      imageAlt: "Фото электронный архив документов кадрового и воинского учёта",
      imageTitle: null,
      list: {
        items: [
          {
            description:
              "Подтверждение стажа, досрочный выход на пенсию или судебные разбирательства",
          },
          {
            description:
              "Удобный доступ к кадровым документам: договорам, карточкам личных дел, справкам и инструкциям",
          },
          {
            description:
              "Поиск и аудит кадровых документов и документов воинского учёта",
          },
          {
            description:
              "Подготовка необходимых документов по воинскому учету по запросу курирующих и надзорных органов: приказов, личных дел и прочее",
          },
          { description: "Интеграция с 1С:ЗУП и др." },
        ],
      },
      control: {
        show: true,
        msgSubmit: "Подробнее",
        title: "Архив кадровых документов и документов воинского учёта",
        description: "",
        descriptionHTML:
          "<h3>Преимущества</h3>" +
          "<p>Система обеспечивает централизованный сбор, хранение и эффективное управление всей кадровой информацией: Кадровые Приказы, трудовые договора, договора ГПХ, должносные инструкции, любые документы воинского учета и карточки личных дел (Т2) и скан-копиями бумажных документов.</p>" +
          "<p>Хранение документов воинского учета и личных карточек сотрудников компании.</p>" +
          "<p>Ведение кадрового делопроизводства и архивное хранение кадровых документов и документов винского учета в соответствии с законодательством РФ и внутренними (локальными) нормативными актами организации.</p>",
      },
    },
    {
      title: "Организационно-распорядительная и юридическая документация",
      subtitle: "",
      description1:
        "Ораганизация хранениия любых видов организационно-распорядительной документации организации и кадровых документов",
      description2:
        "Договоры, дела, судебные и иные решения, приказы, постановления, нотариально заверенные документы, протоколы совещаний и служебные записки",
      icon: null,
      image: require("assets/images/feature/features1-2_600x600.webp"),
      imageAlt:
        "Фото электронный архив организационно-распорядительной документации и юридических документов",
      imageTitle: null,
      list: {
        items: [
          {
            description:
              "Автоматизирует кадровые процессы (подбор, приём на работу, увольнение) и консолидирует информацию о сотрудниках в личных делах",
          },
          {
            description:
              "Учёт и хранение документов содержащих персональные данные согласно требованиями ФЗ-152",
          },
          {
            description:
              "Динамические поля позволят вести учет архивной документации в разрезе проектов",
          },
        ],
      },
    },
    {
      title: "Технические, научные и проектные документы",
      subtitle: "",
      description1:
        "Централизованный электронный архив на отечественной платформе предоставит оперативный доступ к технической документации в электронном виде для всех заинтересованных служб и специалистов",
      description2:
        "Ораганизация хранениия научных и проектных документов, чертежей, карт, полиграфической продукции, смет, планов, извещений и разрешений, документов в плохом состоянии",
      icon: null,
      image: require("assets/images/feature/features1-3_600x600.webp"),
      imageAlt: "",
      imageTitle: null,
      list: {
        items: [
          {
            description:
              "Организация хранения в соответсвии с требованиями ГОСТ",
          },
          {
            description:
              "Готовые модули интеграции с внешними информационными системами: MDM, CAD/CAM, PLM/PDM, СЭД, ERP, CRM, в том числе SAP MDM, SAP HR, SAP ТОРО, 1С и другие",
          },
          {
            description:
              "Повышение эффективности производственных процессов за счет оперативного доступа к документации в электронном виде, возможность работы с проектными документами на всех стадиях разработки",
          },
        ],
      },
      control: {
        show: true,
        msgSubmit: "Подробнее",
        title: "Архив технической документации",
        description: "",
        descriptionHTML:
          "<h3>Преимущества</h3>" +
          "<p>Повышение скорости и качества доступа к технической информации, а также автоматизация процессов профильного документооборота стало возможным благодаря этой системе. Консолидированный электронный архив объединяет различные типы документов – конструкторскую, проектно-сметную, научно-исследовательскую, эксплуатационную, сопроводительную, что обеспечивает непрерывность производственного цикла, формирование необходимых подборок документов и оперативное представление информации для процессов разработки и обслуживания.</p>" +
          "<p>Электронный архив предоставляет удобную навигационную систему для того, чтобы упорядочить и упростить поиск информации. Система навигации по архивным документам обеспечивает поиск: по наименованиям, категориям и ключевым словам, по принадлежности к продукции или проекту, к которой она относится, к любой сопутствующей документации.</p>" +
          "<p>Благодаря использованию в документах версионности и разграничения прав доступа обеспечивается их безопасность, а также благодаря функциям репликации и защиты. С помощью современной архитектуры системы можно обеспечить хранение большого количества информации без потери производительности и работы с любыми форматами данных - копиями, таблицами, программами для создания изображений, графическими материалами.</p>",
      },
    },
    {
      title: "Универсальный электронный архив",
      subtitle: "",
      description1:
        "Хранение электронных копий документов бумажного архива, оцифрованная документация организации",
      description2: "",
      icon: null,
      image: require("assets/images/feature/features1-4_600x600.webp"),
      imageAlt: "",
      imageTitle: null,
      list: {
        items: [
          {
            description:
              "Долговременное хранени любых документов и данных предприятия",
          },
          {
            description:
              "Мгновенный поиск по атрибутам документов и справочники, которые синхронизиру­ются с учетными системами компании",
          },
          {
            description:
              "Подготовка подборок документов в различных разрезах: по контрагенту, сумме, дате, хозяй­ственной операции, по клиенту и оказанной услуге и т.д.",
          },
          {
            description: "Формирование статистических отчетов",
          },
        ],
      },
    },
  ],
};

export const dataServicesInfoMainPage = {
  title: "Наши услуги и сервисы",
  subtitle: "Наши услуги и сервисы",
  description:
    "Комплексные услуги по работе с архивамии и переводу документов организации в электронный вид",
  items: [
    {
      title: "Перевод документов в электронный вид",
      subtitle: "",
      description:
        "Создание электронного архива документов c нами - это верный шаг к увеличению прибыли и развитию",
      image: require("assets/images/services/service-box-1.webp"),
      icon: <BiCapsule />,
      list: {
        items: [
          { description: "Создание электронного архива", link: "/" },
          { description: "Электронная опись", link: "/" },
          {
            description: "Сканированиие и оцифровка",
            link: "/services/scanning",
          },
          { description: "Автоматическое и  ручное распознавание", link: "/" },
        ],
      },
    },
    {
      title: "Архивные услуги",
      subtitle: "",
      description:
        "Архивная обработка документов представляет собой основной этап построения структуры архива.",
      image: require("assets/images/services/service-box-1.webp"),
      icon: <TfiShortcode />,
      list: {
        items: [
          { description: "Архивная обработка документов", link: "/" },
          { description: "Переплет документов", link: "/" },
          { description: "Сдача документов в архив", link: "/" },
          { description: "Уничтожение документов", link: "/" },
        ],
      },
    },
  ],
};

export const dataSloganMainPage = {
  title: "Российская платформа для управления электронным архивом организации",
  subtitle: "еще один заголовок",
  // "Aрхивное хранение электронных документов и данных, систематизация и поиск, контроль доступа к контенту",
  // "Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  description1:
    "Комплексное внедрение автоматизированных информационных систем электронного архива. Быстрая миграция документов из текущих информационных систем, непрерывность функциональных процессов.",
  description2:
    "Готовые решения и дополнительные интеграционные модули как для работы в облаке (модель SAAS), так и для разворачивания в локальной сети организации и использования собственных вычислительных мощьностей.",
};

export const dataOurProductsMainPage = {
  title: "Наши решения и продукты",
  subtitle: "Наши решения и продукты",
  description:
    "Програмное обесечение собственной разработки, внесенное в реестр отечественного ПО",
  image: null,
  imageAlt: "",
  items: [
    {
      title: "Электронный архив",
      image: require("assets/icons/theme-icon13.png"),
      description:
        "Доступное и быстрое решение задач по обработке и хранению корпоративных документов.",
      link: "/archive",
    },
    {
      title: "Модуль поиска данных",
      image: require("assets/icons/theme-icon10.png"),
      description: "",
      link: "/archive",
    },
    {
      title: "Модуль распознавания",
      image: require("assets/icons/theme-icon9.png"),
      description: "",
      link: "/archive",
    },
    {
      title: "Модуль интеграции с 1С",
      image: require("assets/icons/theme-icon2.png"),
      description: "",
      link: "/archive",
    },
    {
      title: "Управление финансовой документацией",
      image: require("assets/icons/theme-icon3.png"),
      description:
        "Система обеспечивает централизованный сбор, хранение и эффективное управление всей бухгалтерской информацией: документами ЭДО и скан-копиями бумажных документов.",
      link: "/archive",
    },
    {
      title: "Управлениие технической документацией",
      image: require("assets/icons/theme-icon6.png"),
      description:
        "Система увеличивает скорость доступа к технической информации и автоматизирует процессы профильного документооборота.",
      link: "/archive",
    },
    {
      title: "Управлениие технической документацией",
      image: require("assets/icons/theme-icon7.png"),
      description:
        "Система увеличивает скорость доступа к технической информации и автоматизирует процессы профильного документооборота.",
      link: "/archive",
    },
  ],
};

export const dataServiceMainPage = {
  title: "Cервисы и программное обеспечение для ваших задач",
  subtitle: "Cервисы и программное обеспечение для ваших задач",
  description:
    "Наш электронный архив - модульная архитектура, отечественная разработка и гибкая лицензионная политика и большой выбор функциональных модулей",
  items: [
    {
      title: "Электронный архив органиазции",
      subtitle: "",
      description:
        "Локальные или облачные решения для организации электронных архивов для хранения юрижически значимых документов, кажровых документов, технической и конструкторсой документации и данных для малых, средних или крупных предприятий.",
      image: require("assets/images/services/service-1_800x450.webp"),
      icon: <BiCapsule />,
    },
    {
      title: "Соблюдение законодательных норм и процедур хранения",
      subtitle: "",
      description:
        "Бумажные оригиналы сканируются и отправляются в систему автоматически с помощью службы массового ввода. Электронные документы передаются в архив из любой оперативной системы в едином формате.",
      image: require("assets/images/services/service-2_800x450.webp"),
      icon: <TfiShortcode />,
    },
    {
      title: "Сохранение юридической значимости документовн",
      subtitle: "",
      description:
        "Гарантия сохранения юридической значимости электронных документов в течение всего срока хранения.",
      image: require("assets/images/services/service-3_800x450.webp"),
      icon: <TfiReload />,
    },
    {
      title: "Решения для различных отраслей",
      subtitle: "",
      description:
        "Местонахождение бумажных оригиналов зафиксировано в единой системе, а отследить движение документа можно по штрихкоду на его копии.",
      image: require("assets/images/services/service-4_800x450.webp"),
      icon: <TfiServer />,
    },
    {
      title: "Модули интеграции",
      subtitle: "",
      description:
        "Коннекторы для интеграции с ERP, СЭД и другими системами, поддержка ЮЗДО и взаимодействие с операторами ЭДО, настройка индивидуальных интеграционных решений.",
      image: require("assets/images/services/service-5.webp"),
      icon: <TfiInfinite />,
    },
    {
      title: "Непревзайденная производительность",
      subtitle: "",
      description:
        "Уменьшение нагрузки оперативные системы учета. Снижение стоимости хранения за счет меньших требований к оборудованию и централизации всех архивных процедур.",
      image: require("assets/images/services/service-6.webp"),
      icon: <TfiDashboard />,
    },
  ],
};
