import {
  InputFloatingLabelEmail,
  InputFloatingLabelName,
  InputFloatingLabelPhone,
} from "components/utils/Forms";
import { dataForm } from "data";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _debug } from "utils";
import { msgSubmit, msgSubmitted } from "./CallToAction";

const msgTitle = "Заявка на консультацию";

const FormOrder = (props) => {
  const {
    methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data) => {
    _debug("Form submit: ", data);

    setError(null);

    try {
      //gaEventTracker("submitFromCountDown");
      const response = await props.onSubmit({
        email: data.email,
      });

      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
        return;
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    } finally {
      reset();
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="form-header mb-3">
        <h3>{props.title || msgTitle}</h3>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={props.handleClose}
        ></button>
      </div>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete={"off"}
        method="POST"
        className="contact-form mb-5"
      >
        <Row className="g-3 mb-3">
          <Form.Group controlId="formContactName" as={Col}>
            <InputFloatingLabelName
              label={dataForm.name.msgLebel}
              placeholder={dataForm.name.msgPlaceholder}
              required={dataForm.name.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>
        </Row>
        <Row className="g-3 mb-3">
          <Form.Group controlId="formContactEmail" as={Col}>
            <InputFloatingLabelEmail
              label={dataForm.email.msgLebel}
              placeholder={dataForm.email.msgPlaceholder}
              required={dataForm.email.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>
        </Row>
        <Row className="g-3 mb-3">
          <Form.Group controlId="formContactPhone" as={Col}>
            <InputFloatingLabelPhone
              label={dataForm.phone.msgLebel}
              placeholder={dataForm.phone.msgPlaceholder}
              required={dataForm.phone.msgRequired}
              register={register}
              control={control}
              errors={errors}
            />
          </Form.Group>
        </Row>
        <div className="text-center mt-3">
          <button type="submit" className="btn btn-primary theme-btn mx-auto">
            {msgSubmit}
          </button>
        </div>
        {error && (
          <p className="text-warning" style={{ textAlign: "center" }}>
            <small>{error}</small>
          </p>
        )}
        {success && (
          <p className="text-success" style={{ textAlign: "center" }}>
            <small>{msgSubmitted}</small>
          </p>
        )}
      </Form>
      <div className="form-footer">
        <p>
          *Отправляя форму, вы соглашаетесь с условиями{" "}
          <Link to="/policies/privacy_policy_ru_v1_02.pdf" target="_blank">
            обработки персональных данных
          </Link>
        </p>
      </div>
    </FormProvider>
  );
};

export default FormOrder;
