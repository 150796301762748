const InputGroupError = (props) => {
  const { message } = props;

  if (!message) return null;

  return (
    <p className="text-warning">
      <small>{message}</small>
    </p>
  );
};

export default InputGroupError;
