import { registerOrder } from "actions/actions";
import background from "assets/images/bg/cta-bg.webp";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FormOrder from "./FormOrder";

//import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

//import "react-phone-number-input/style.css";

export const msgCTA = "Заказать";
export const msgSubmitted = "Заказ успешно направлен";
export const msgSubmit = "Заказать";

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <img src={background} alt="" />

      <div className="container">
        <div
          className="row justify-content-center aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="col-xl-10">
            <div className="text-center">
              <h3 className="mb-2">Call To Action</h3>
              <p className="mb-1">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
              <a className="cta-btn mt-1" href="/">
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const SectionContent2 = (props) => {
  const {
    data,
    id,
    cn,
    showSubtitle,
    showDescription,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;
  const isMobile = useIsMobile();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Modal1 = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="order-form-modal"
      >
        <Modal.Body>
          <div className="contact-form-wrapper">
            <FormOrder
              title={data.title1}
              onSubmit={registerOrder}
              handleClose={handleClose}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <section
      id={id || "call-to-action"}
      ref={innerRef}
      style={{
        backgroundImage: data.backgroundImage
          ? `url(${data.backgroundImage})`
          : `url(${background})`,
      }}
      className="call-to-action-section cta-bg img-bg pt-110 pb-100"
    >
      <Modal1 />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-5">
            <div className="section-content-title mb-60">
              {showSubtitle && data.subtitle && !isMobile && (
                <span
                  className="aos-animate fadeUp"
                  {...(useAnimation && {
                    "data-aos": "fade-up",
                    "data-aos-delay": 200,
                  })}
                >
                  {data.subtitle}
                </span>
              )}
              <h2
                className="mb-3 text-white aos-animate fadeUp"
                {...(useAnimation && {
                  "data-aos": "fade-up",
                  "data-aos-delay": 400,
                })}
              >
                {data.title}
              </h2>
              {showDescription && (
                <p
                  className="text-white aos-animate fadeUp"
                  {...(useAnimation && {
                    "data-aos": "fade-up",
                    "data-aos-delay": 600,
                  })}
                >
                  {data.description}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-5 col-lg-7">
            <div className="contact-btn text-center text-lg-center">
              <Button
                className="btn theme-btn"
                variant="primary"
                onClick={handleShow}
                aria-label={data.msg.msgCTA || msgCTA}
              >
                {data.msg.msgCTA || msgCTA}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

/**
 * Component for showing Section "Call To Action" of the site.
 *
 * @component
 */
const CallToAction = (props) => {
  const {
    data,
    id = "call-to-action",
    cn = "pt-70 pb-70 call-to-action",
    showDescription,
    showSubtitle,
    innerRef,
    ...otherProps
  } = props;

  /* <PageSection id={id} cn={cn}>
      <SectionContent data={data} />
      
    </PageSection>
   */
  return (
    <SectionContent2
      data={data}
      id={id}
      cn={cn}
      showDescription={showDescription}
      showSubtitle={showSubtitle}
      innerRef={innerRef}
      {...otherProps}
    />
  );
};

CallToAction.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

CallToAction.defaultProps = {
  showSubtitle: false,
  showDescription: true,
  useAnimation: false,
};

export default CallToAction;
