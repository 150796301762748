import classnames from "classnames";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

/**
 * Component for showing page "Section" of the site.
 *
 * @component
 */
export const PageSection = (props) => {
  const { children, id, cn, ...otherProps } = props;
  let cnTmp = id ? `page-section ${id}-section` : `page-section`;

  return (
    <section className={classnames(cnTmp, cn)} id={id} {...otherProps}>
      {children}
    </section>
  );
};

PageSection.propTypes = {
  id: PropTypes.string,
  cn: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/**
 * Component for showing section "Title" of the site.
 *
 * @component
 */
export const SectionTitle = (props) => {
  const { data, showSubtitle, showDescription, useAnimation } = props;
  const isMobile = useIsMobile();

  return (
    <Fragment>
      {showSubtitle && data.subtitle && !isMobile && (
        <span
          className="aos-animate fadeUp"
          {...(useAnimation && {
            "data-aos": "fade-up",
            "data-aos-delay": 200,
          })}
        >
          {data.subtitle}
        </span>
      )}
      <h2
        className={classnames("aos-animate fadeUp", {
          "mb-3": showDescription,
        })}
        {...(useAnimation && { "data-aos": "fade-up", "data-aos-delay": 400 })}
      >
        {data.title}
      </h2>
      {showDescription && (
        <p
          className="aos-animate"
          {...(useAnimation && {
            "data-aos": "fade-up",
            "data-aos-delay": 600,
          })}
        >
          {data.description}
        </p>
      )}
    </Fragment>
  );
};

SectionTitle.propTypes = {
  //data: PropTypes.object,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
  }),
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? true,
};

/**
 * Component for showing section "Header" of the site.
 *
 * @component
 */
export const SectionHeader = (props) => {
  const { data, showDescription, showSubtitle } = props;

  //  <div className="col-xl-7 col-lg-8 col-md-9 mx-auto">
  return (
    <div className="row">
      <div className="mx-auto">
        <div className="section-title mb-lg-5 mb-3">
          <SectionTitle
            data={data}
            showDescription={showDescription}
            showSubtitle={showSubtitle}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

SectionHeader.propTypes = {
  data: PropTypes.object,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
};

/**
 * Component for showing content section "Header" of the site.
 *
 * @component
 */
export const SectionContentHeader = (props) => {
  const { data, showDescription } = props;

  return (
    <div className="section-content-title">
      <SectionTitle data={data} showDescription={showDescription} {...props} />
    </div>
  );
};

SectionContentHeader.propTypes = {
  data: PropTypes.object,
  showDescription: PropTypes.bool ?? true,
};
