import { dataMetaAboutPage } from "./meta/dataMetaAboutPage";
import { dataMetaBlogPage } from "./meta/dataMetaBlogPage";
import { dataMetaContactsPage } from "./meta/dataMetaContactsPage";
import { dataMetaCountDownPage } from "./meta/dataMetaCountDownPage";
import { dataMetaFAQPage } from "./meta/dataMetaFAQPage";
import { dataMetaHomePage } from "./meta/dataMetaHomePage";
import { dataMetaNotFoundPage } from "./meta/dataMetaNotFoundPage";
import { dataMetaPartnersPage } from "./meta/dataMetaPartnersPage";
import { dataMetaPayPolicyPage } from "./meta/dataMetaPayPolicyPage";
import { dataMetaPolicyPage } from "./meta/dataMetaPolicyPage";
import { dataMetaProductPage } from "./meta/dataMetaProductPage";
import { dataMetaServicesPage } from "./meta/dataMetaServicesPage";

export const dataHomePage = {
  title: "Hello World",
  subtitle: "",
  description: "",
  meta: dataMetaHomePage,
};

export const dataContactsPage = {
  title: "Связаться с нами",
  subtitle: "Готовы начать работу",
  description: "Напишите нам",
  meta: dataMetaContactsPage,
  extraData: {
    title: "Напишите нам",
    subtitle: "Напишите нам",
    description: "",
  },
};

export const dataAboutPage = {
  title: "О компании",
  subtitle: "О компании",
  description: "О компании",
  meta: dataMetaAboutPage,
};

export const dataFAQPage = {
  title: "Часть задаваемые вопросы (FAQ)",
  subtitle: "Свяжитесь с нами, если у вас есть вопрос!",
  description: "Свяжитесь с нами, если у вас есть вопрос!",
  meta: dataMetaFAQPage,
};

export const dataBlogPage = {
  title: "Блог",
  subtitle: "Блог",
  description: "Блог",
  meta: dataMetaBlogPage,
};

export const dataServicesPage = {
  title: "Наши услуги",
  subtitle: "Наши услуги",
  description: "Наши услуги",
  meta: dataMetaServicesPage,
  extraData: {
    title: "Наши услуги",
    subtitle: "Наши услуги",
    description: "",
  },
};

export const dataPartnersPage = {
  title: "Партнерство",
  subtitle: "Партнерство",
  description: "Партнерство",
  meta: dataMetaPartnersPage,
  extraData: {
    title: "Партнерство",
    subtitle: "Партнерство",
    description: "",
  },
};

export const dataPolicyPage = {
  title: "Политики",
  subtitle: "Политики конфидециальности и обработки персональных данных",
  description: "Политики конфидециальности и обработки персональных данных",
  meta: dataMetaPolicyPage,
};

export const dataPayPage = {
  title: "Проведение оплат и расчетов",
  subtitle: "Политика проведения оплат и расчетов",
  description: "Политика проведения оплат и расчетов",
  meta: dataMetaPayPolicyPage,
};

export const dataProductPage = {
  title: "О продукте",
  subtitle: "О продукте",
  description: "О продукте",
  meta: dataMetaProductPage,
  extraData: {
    title: "О продукте",
    subtitle: "О продукте",
    description: "",
  },
};

export const dataSerachPage = {
  title: "Поиск по сайту",
  subtitle: "Поиск",
  description: "Введите фразу в поле для поиска.",
  meta: null,
};

export const dataTypographyPage = {
  title: "Typography page",
  subtitle: "Typography page",
  description: "Typography page",
  meta: null,
};

export const dataNotFoundPage = {
  title: "Страница не найдена - 404",
  subtitle: "Похоже, запрашивая страница больше не существует...",
  description:
    "Страница, которую вы ищете, могла быть удалена, если бы она была имя изменено или временно недоступно.",
  meta: dataMetaNotFoundPage,
};

export const dataCountDownPage = {
  title: "",
  subtitle: "",
  description: "",
  meta: dataMetaCountDownPage,
};
