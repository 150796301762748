import { API_URI } from "config/config";
import DOMPurify from "isomorphic-dompurify";

const SinglePost = (props) => {
  const { data: postData } = props;

  return (
    <div className="single-blog blog-style-2">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="blog-img">
            <img
              className="img-fluid"
              src={`${API_URI}/posts/imagedb/${postData.imageURL}`}
              alt={
                postData.imageAlt || "Система управления электронным архивом"
              }
            />
          </div>
        </div>
      </div>

      <h2 className="my-4">{DOMPurify.sanitize(postData.title)}</h2>

      <div className="site-content blog-content">
        <div className="col-md-10">
          <p className="fst-italic">{postData.description}</p>
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(postData.content),
          }}
        ></div>
      </div>
    </div>
  );
};

export default SinglePost;
