import { dataSite } from "data/dataSite";

export const dataMetaCountDownPage = {
  title: "Запуск сервиса архива электронных документов «ELARCH»",
  //  Система электронного архива "«ELARCH»": цифровое хранение документов
  description: `Запуск сервиса архива электронных документов «ELARCH» для организации архива электронных документов и автоматизации делопроизводства. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
  name: "",
  type: "",
  author: "ELARCH, Inc.",
  ExtraMetaTags: [{ name: "robots", content: "noindex, follow" }],
  OG: [
    {
      property: "og:title",
      content: "Запуск сервиса архива электронных документов «ELARCH»",
    },
    {
      property: "og:description",
      content: `Запуск сервиса архива электронных документов «ELARCH» для организации архива электронных документов и автоматизации делопроизводства. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
    },
    {
      property: "og:url",
      content: "https://elarch.ru/countdown/",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    { property: "og:image:alt", content: "ELARCH" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "ELARCH",
    },
  ],
  TW: [
    { name: "twitter:creator", content: "" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "" },
    { name: "twitter:description", content: "" },
    { name: "twitter:label1", content: "Примерное время для чтения" },
    { name: "twitter:data1", content: "10 минуты" },
  ],
};
