import { FloatingLabel, Form } from "react-bootstrap";
import InputGroupError from "./InputGroupError";

export const InputFloatingLabelMessage = (props) => {
  const { label, placeholder, required, register, errors, ...otherProps } =
    props;

  return (
    <FloatingLabel>
      <Form.Control
        as="textarea"
        rows={5}
        name="message"
        placeholder={placeholder}
        isInvalid={!!errors.message}
        {...register("message", {
          required: required,
          validate: {
            maxLength: (v) =>
              v.length <= 250 ||
              "The message should have not more 250 characters",
            minLength: (v) => v.length > 1 || "The message should be entered",
          },
        })}
        {...otherProps}
        aria-invalid={errors?.message ? "true" : "false"}
        aria-describedby="messageHelpBlock"
        style={{ height: "10em" }}
      />
      <Form.Label>{label || "Message"}</Form.Label>
      {errors.message && (
        <Form.Control.Feedback type="invalid">
          <InputGroupError message={errors.message.message} />
        </Form.Control.Feedback>
      )}
    </FloatingLabel>
  );
};

const InputGroupMessage = (props) => {
  const {
    label,
    placeholder,
    help,
    required,
    register,
    errors,
    id,
    ...otherProps
  } = props;

  return (
    <Form.Group controlId={id || "message"}>
      <InputFloatingLabelMessage
        label={label}
        placeholder={placeholder}
        required={required}
        register={register}
        errors={errors}
        {...otherProps}
      />
      {help && (
        <Form.Text
          id="messageHelpBlock"
          muted
          //hidden={!(errors.message && errors.message.message)}
        >
          {help}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default InputGroupMessage;
