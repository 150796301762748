import { formatDataShort } from "components/utils/Utils";
import { API_URI } from "config/config";
import { useFetch } from "hooks";
import { Fragment, useState } from "react";
import { Pagination, Row } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { useParams } from "react-router-dom";
//import { logger } from "utils";
import { Spinner } from "components/utils/Spinner";
import { BLOG_ID } from "config/config";
import { dataForm } from "data";
import { LinkToPost } from "./BlogUtils";

let PageSize = 3;

/**
 * Component for showing "Blog list of posts by Catogory page" of the site.
 *
 * @component
 */
const PostsByCategoryPage = (props) => {
  const { slug } = useParams();
  const URL = `${API_URI}/posts/category/${slug}?`;
  const {
    data: postPages,
    isLoading,
    hasError,
    errorMessage,
    updateUrl,
    updateParams,
  } = useFetch(URL, {
    pageNo: 0,
    pageSize: 5,
    blog: BLOG_ID,
  });
  const { page, setPage } = useState(0);
  const [state, setState] = useState({
    data: [],
    limit: 10,
    activePage: 1,
  });

  //console.log(postPages);

  //const [currentPage, setCurrentPage] = useState(1);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return blogPosts?.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    //logger.log(pageNumber);
    // setState((prev) => ({ ...prev, activePage: pageNumber }));
    updateParams({
      pageNo: pageNumber,
    });
  };

  const PostListItem = (props) => {
    const { data, index } = props;
    console.log(data);

    return (
      <div className="col-md-6 col-lg-12 col-xl-6">
        <div
          className="single-blog mb-40 wow fadeInUp"
          data-wow-delay=".2s"
          style={{
            visibility: "visible",
            animationDelay: "0.2s",
            animationName: "fadeInUp",
          }}
        >
          <div className="blog-img">
            <LinkToPost slug={data.slug}>
              <img src={data.image} alt="" />
            </LinkToPost>
            <span className="blog-meta-date">15 June, 2025</span>
          </div>
          <div className="blog-content">
            <LinkToPost slug={data.slug}>
              <h4>{data.title} </h4>
            </LinkToPost>
            <p>{data.description}</p>
            <LinkToPost slug={data.slug} className="read-more-btn">
              {dataForm.msgReadMore} <FaArrowRightLong />
            </LinkToPost>
          </div>
        </div>
      </div>
    );
  };

  const PostListItem2 = (props) => {
    const { data, index } = props;

    return (
      <div
        className="single-blog blog-style-2 mb-60 wow fadeInUp"
        data-wow-delay=".2s"
        style={{
          visibility: "visible",
          animationDelay: "0.2s",
          animationName: "fadeInUp",
        }}
      >
        <div className="blog-img">
          <LinkToPost slug={data.slug}>
            <img src={`${API_URI}/posts/imagedb/${data.imageURL}`} alt="" />
          </LinkToPost>
        </div>
        <div className="blog-content">
          <div className="small text-muted mb-2">
            {formatDataShort(
              data.updatedDate ? data.updatedDate : data.createdDate
            )}
          </div>

          <LinkToPost slug={data.slug}>
            <h4>{data.title} </h4>
          </LinkToPost>

          <p>{data.description}</p>
          <div className="blog-meta">
            <LinkToPost slug={data.slug} className="read-more-btn">
              {dataForm.msgReadMore} <FaArrowRightLong />
            </LinkToPost>
            {/*
            <span className="comment">
              <i className="lni lni-bubble"></i> 1K Comment
              </span>
              */}
          </div>
        </div>
      </div>
    );
  };

  const ListPagination = () => {
    const getPaginationItems = (items) => {
      let content = [];
      for (let index = 1; index <= postPages.totalPages; index++) {
        //console.log(index);
        //const item = animals[i];
        content.push(
          <Pagination.Item
            onClick={() => handlePageChange(index)}
            key={index}
            active={index === postPages.page + 1}
          >
            {index}
          </Pagination.Item>
        );
      }
      return content;
    };

    return (
      <div className="pagination">
        <Pagination className="d-flex justify-content-center align-items-center px-4">
          <Pagination.First
            disabled={postPages.page === 0}
            onClick={() => handlePageChange(1)}
          />
          {getPaginationItems()}
          <Pagination.Last
            disabled={postPages.page === postPages.totalPages - 1}
            onClick={() => handlePageChange(postPages.totalPages)}
          />
        </Pagination>
      </div>
    );
  };

  if (isLoading || hasError) {
    return (
      <Fragment>
        {isLoading && <Spinner />}
        {hasError && <p>{errorMessage}</p>}
      </Fragment>
    );
  }

  if (!postPages) return null;

  if (!postPages.content || postPages.content.length === 0) {
    return <h2>No posts founds</h2>;
  }

  return (
    <div className="posts-list-wrapper">
      <Row>
        {postPages &&
          postPages.content &&
          postPages.content.length &&
          postPages.content.map((item, index) => {
            return <PostListItem2 data={item} key={index} />;
          })}
      </Row>
      <ListPagination />
    </div>
  );
};

export default PostsByCategoryPage;
