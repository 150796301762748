import { PropTypes } from "prop-types";
import { FaArrowRight as Icon1 } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { PageSection } from "../Home";
//import background from "../../../assets/carousel.jpg";

//import Image1_2 from "assets/dots.shape.svg";

const msgGetStarted = "Заказать";

const SectionContent = (props) => {
  const { data } = props;

  return (
    <div className="row">
      <div className="col-lg-6 d-flex flex-column justify-content-center">
        <h2
          className="aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          {data.subtitle}
        </h2>
        <h1 className="aos-init aos-animate" data-aos="fade-up">
          {data.title}
        </h1>
        <p
          className="hero-content-description aos-animate"
          data-aos="fade-left"
          data-aos-delay="600"
        >
          {data.description}
        </p>
        <div
          data-aos="fade-up"
          data-aos-delay="600"
          className="aos-init aos-animate"
        >
          <div className="hero-content-control text-center text-lg-start">
            <Link
              href="#about"
              className="btn theme-btn scrollto d-inline-flex align-items-center justify-content-center align-self-center"
            >
              <span>{msgGetStarted}</span>
              <Icon1 />
            </Link>
          </div>
        </div>
      </div>
      <div
        className="col-lg-6 hero-img aos-init aos-animate"
        data-aos="zoom-out"
        data-aos-delay="200"
      >
        <img className="img-fluid" src={data.image} alt={data.imageAlt} />
      </div>
    </div>
  );
};

/**
 * Component for showing Section "Hero" of the site.
 *
 * @component
 */
const Hero2 = (props) => {
  const {
    data,
    id = "product-hero",
    cn = "hero d-flex align-items-center",
  } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

Hero2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Hero2;
