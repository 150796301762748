import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataPayPage, dataSubscribe } from "data";
import { Fragment } from "react";
import { Subscribe2 } from "../Home";
import SectionPay from "./SectionPay";

/**
 * Component for showing Page "Pay Policy" of the site.
 *
 * @component
 */
const PayPage = () => {
  return (
    <Fragment>
      <SEO data={dataPayPage.meta}>
        <link rel="canonical" href="https://elarch.ru/policy/pay/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-policy">
        <Banner data={dataPayPage} />
        <SectionPay data={null} />
        <Subscribe2 data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default PayPage;
