import { isDevelopment } from "components/utils/Utils";
import { APP_COOKIE_NAME } from "config/config";
import { dataForm } from "data";
import CookieConsent from "react-cookie-consent";
import { Link, useNavigate } from "react-router-dom";

//     This website stores cookies on your computer. These cookies are used to improve your website experience and provide more personalized services to you. To find out more about the cookies we use, see our ",
const dataSection = {
  title: "",
  subtitle: "",
  description: null,
  descriptionHTML:
    `На информационном ресурсе применяются рекомендательные технологии.` +
    `<br/ >` +
    `Продолжая оставаться на сайте, вы соглашаетесь с ` +
    `<a className="btn btn-link link" aria-label="Conditions" onClick={handleShowPolicy}><span>политикой</span></a>` +
    ` использования файлов Cookie-файлов.`,
};

const CookiePolicy = () => {
  const navigate = useNavigate();

  const handleShowPolicy = (e) => {
    navigate("/policy");
  };

  return (
    <div className="cookieconsent-wrapper">
      <div className="container">
        <CookieConsent
          debug={isDevelopment}
          cookieName={APP_COOKIE_NAME}
          buttonText={dataForm.msgPolicyAgreed}
          acceptOnScroll={true}
          acceptOnScrollPercentage={30}
          ariaAcceptLabel="cookieconsent"
          disableStyles={true}
          containerClasses="row cookieconsent cookieconsent-glass"
          contentClasses="col-lg-10"
          buttonWrapperClasses="col-lg-2"
          buttonClasses="btn theme-btn cookieconsent-btn"
        >
          На информационном ресурсе применяются рекомендательные технологии.
          <br />
          Продолжая оставаться на сайте, вы соглашаетесь с{" "}
          <Link
            className="btn-link"
            aria-label="Conditions"
            onClick={handleShowPolicy}
          >
            <span>политикой</span>
          </Link>{" "}
          использования файлов Cookie-файлов.
        </CookieConsent>
      </div>
    </div>
  );
};

export default CookiePolicy;
