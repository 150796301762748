import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { API_URI } from "config/config";
import { useFetch } from "hooks";
import { useMemo, useReducer } from "react";
import { Button, Pagination, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const columnHelper = createColumnHelper();

const Table1 = (props) => {
  const navigate = useNavigate();
  const { data } = props;
  const rerender = useReducer(() => ({}), {})[1];
  const columns = useMemo(() => [
    columnHelper.accessor("id", {
      header: () => "ID",
      cell: (info) => info.getValue(),
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("title", {
      header: () => "Title",
      cell: (info) => info.getValue(),
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("category.name", {
      header: () => <span>Category</span>,
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("authorName", {
      header: "Author",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("published", {
      header: "Published",
      //footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: "actions",
      cell: (info) => (
        <Button
          onClick={() => {
            handlePostEdit(info.row);
          }}
        >
          Follow
        </Button>
      ),
    }),
  ]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handlePostEdit = (row) => {
    //this.setState({ isFollow: !this.state.isFollow });
    console.log(row);
    navigate(`/posts/${row.original.slug}/edit`);
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const PaginationBasic = () => {
    return (
      <div>
        <Pagination>{items}</Pagination>
        <br />

        <Pagination size="lg">{items}</Pagination>
        <br />

        <Pagination size="sm">{items}</Pagination>
      </div>
    );
  };

  return (
    <div className="p-2">
      <Table striped bordered hover>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </Table>
      <div className="h-4" />
      <PaginationBasic />
      <button onClick={() => rerender()} className="border p-2">
        Rerender
      </button>
    </div>
  );
};

const AppTable = () => {
  //const [data, setData] = useState(() => [...defaultData]);
  const URL = `${API_URI}/posts`;
  console.log(URL);
  const { data: postsData, isLoading, hasError, errorMessage } = useFetch(URL);

  console.log(postsData);

  if (!postsData) return null;

  if (isLoading || hasError) return <h1>{errorMessage}</h1>;

  return <Table1 data={postsData.content} />;
};

export default AppTable;
