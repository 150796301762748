import { SocialLinks } from "components/utils/Social";
import { dataSocialLinks } from "data";

const BlogSocial = () => {
  return (
    <div className="sidebar-box mb-30">
      <h4>Мы в соцсетях</h4>
      <SocialLinks data={dataSocialLinks} />
    </div>
  );
};

export default BlogSocial;
