import DOMPurify from "isomorphic-dompurify";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Accordion } from "react-bootstrap";

/**
 * Component for showing Section "Feature" of the site.
 *
 * @component
 */
const FAQAccordion = (props) => {
  const { data, showMain, screenCol } = props;

  let items = data.items;

  if (screenCol === 1) {
    items = items.filter((item, index) => index % 2);
  } else if (screenCol === 2) {
    items = items.filter((item, index) => (index + 1) % 2);
  } else {
    if (showMain) {
      items = items.filter((item) => item.main === true);
    }
  }

  const AccordionItem = ({ item, index }) => {
    return (
      <Accordion.Item
        eventKey={index}
        key={index}
        className="faq-item single-accordion"
      >
        <Accordion.Header as="h3" className="faq-header accordion-btn">
          <span>{item.title}</span>
        </Accordion.Header>
        <Accordion.Body className="faq-content accordion-content">
          {item.componentHTML ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.componentHTML.data),
              }}
            ></div>
          ) : (
            <p>{item.description}</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const AccordionItems = (props) => {
    return (
      <Fragment>
        {props.data &&
          props.data.map((item, index) => {
            return <AccordionItem item={item} index={index} key={index} />;
          })}
      </Fragment>
    );
  };

  return (
    <div className="faq-container accordion-style">
      <Accordion defaultActiveKey="0">
        <AccordionItems data={items} />
      </Accordion>
    </div>
  );
};

FAQAccordion.propTypes = {
  data: PropTypes.object,
  showMain: PropTypes.bool.isRequired,
  screenCol: PropTypes.number,
};

FAQAccordion.defaultProp = { showMain: true, screenCol: 0 };

export default FAQAccordion;
