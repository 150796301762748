export const data2 = {
  title: "Возможности",
  subtitle: "Возможности",
  description: "Возможности",
  items: [
    {
      title: "Храните неограниченный объем файлов",
      subtitle: "",
      description:
        "Система использует эффективную распределенную архитектуру, что позволяет масштабировать процессы в неограниченных объемах. Система размещена в облачной конфигурации в датацентре на территории России что позволяет полностью соответствовать законодательству Российской Федерации.",
      image: require("assets/images/app-ss2.webp"),
      imageAlt: "",
    },
    {
      title: "Доступ к просмотру файлов из любой точки мира",
      subtitle: "",
      description:
        "Нет необходимости устанавливать какое-либо программное обеспечение на рабочие станции пользователей. Удобный функционал работы через web и мобильные приложения.",
      image: require("assets/images/app-ss2.webp"),
      imageAlt: "",
    },
    {
      title: "Потоковая загрузка файлов из различных источников",
      subtitle: "",
      description:
        "Система предоставляет различные предустановленные варианты загрузки данных, включая использование встроенной станции сканирования для потоковой конвертации бумажных документов в электронные.",
      image: require("assets/images/app-ss2.webp"),
      imageAlt: "",
    },
    {
      title:
        "Большое количество интеграционных коннекторов к различным источникам данных",
      subtitle: "",
      description:
        "Файловые коннекторы; Коннекторы к различным учетным системам 1С, SAP; К API операторов юзЭДО.",
      image: require("assets/images/app-ss2.webp"),
      imageAlt: "",
    },
    {
      title: "Широкие возможности для интеграции",
      subtitle: "",
      description:
        "Весь функционал системы доступен через REST API. Мы реализуем гибридные решения, сочетающие сбор, обработку и хранение.",
      image: require("assets/images/app-ss2.webp"),
      imageAlt: "",
    },
  ],
};
