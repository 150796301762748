import { FloatingLabel, Form } from "react-bootstrap";
import InputGroupError from "./InputGroupError";

export const InputFloatingLabelEmail = (props) => {
  const { label, placeholder, required, register, errors, ...otherProps } =
    props;

  return (
    <FloatingLabel>
      <Form.Control
        type="email"
        name="email"
        placeholder={placeholder}
        isInvalid={!!errors.email}
        {...register("email", {
          required: required,
          validate: {
            maxLength: (v) =>
              v.length <= 50 ||
              "Адрес электронной почты должен содержать не более 50 символов",
            matchPattern: (v) =>
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "Адрес электронной почты должен быть действительным",
          },
        })}
        {...otherProps}
        aria-invalid={errors?.email ? "true" : "false"}
        aria-describedby="emailHelpBlock"
      />
      <Form.Label>{label || "Email"}</Form.Label>
      {errors.email && (
        <Form.Control.Feedback type="invalid">
          <InputGroupError message={errors.email.message} />
        </Form.Control.Feedback>
      )}
    </FloatingLabel>
  );
};

const InputGroupEmail = (props) => {
  const {
    label,
    placeholder,
    help,
    required,
    register,
    errors,
    id,
    ...otherProps
  } = props;

  return (
    <Form.Group controlId={id || "formEmail"}>
      <InputFloatingLabelEmail
        label={label}
        placeholder={placeholder}
        required={required}
        register={register}
        errors={errors}
        {...otherProps}
      />
      {help && (
        <Form.Text id={id + "HelpBlock" || "formEmailHelpBlock"} muted>
          {help}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default InputGroupEmail;
