import { PropTypes } from "prop-types";
import { BiCheckDouble } from "react-icons/bi";
import { Link } from "react-router-dom";
import { PageSection, SectionHeader } from "../../sections/CommonSection";

// NOTE : https://demo.graygrids.com/themes/saasgrids/

const msgShowMore = "Подробнее";

const SectionItem = (props) => {
  const { data, index } = props;
  const isStyle2 = index % 2 === 0;

  const SectionListItem = () => {
    return (
      <ul>
        {data.list.items.map((itemList, indexList) => {
          if (!itemList.description) return null;
          return (
            <li key={indexList}>
              <i>
                <BiCheckDouble />
              </i>
              {itemList.description}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="col-md-6">
      <div className="service-box box-style box-content service-content">
        <div className="features-item">
          <Link to="/services" className="title mb-3">
            <h3>{data.title}</h3>
          </Link>
          <span className="sub-text mb-2">{data.description}</span>
          <img
            className="my-3"
            src={data.image}
            width="100"
            height="100"
            alt=""
            loading="lazy"
          />
          <span className="line mb-3"></span>
          {data.list.items && data.list.items.length > 0 && <SectionListItem />}

          <Link to="/services" className="btn theme-btn">
            {msgShowMore}
          </Link>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <div className="row equal">
      {data.items.map((item, index) => {
        return <SectionItem data={item} index={index} key={index} />;
      })}
    </div>
  );
};

/**
 * Component for showing Section "Services Info" of the site.
 *
 * @component
 */
const ServicesInfo = (props) => {
  const { data, id = "services-info", cn = "pt-130 pb-40 app-info" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

ServicesInfo.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ServicesInfo;
