import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataPolicyPage, dataSubscribe } from "data";
import { Fragment } from "react";
import { Subscribe2 } from "../Home";
import SectionPolicy from "./SectionPolicy";

/**
 * Component for showing Page "Terms" of the site.
 *
 * @component
 */
const TermsPage = () => {
  return (
    <Fragment>
      <SEO data={dataPolicyPage.meta}>
        <link rel="canonical" href="https://elarch.ru/policy/terms" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-policy-terms">
        <Banner data={dataPolicyPage} />
        <SectionPolicy data={null} />
        <Subscribe2 data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default TermsPage;
