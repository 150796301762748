import {
  AdminPage,
  CountDownPage,
  FAQPage,
  HomePage,
  PayPage,
  ProductPage,
  ScanningPage,
} from "components/pages";

import { SearchPage, ServicesPage } from "components/pages";
import AboutPage from "components/pages/About/AboutPage";
import { AuthPage, ForgotPage } from "components/pages/Auth";
import {
  BlogLayout,
  PostsByCategoryPage,
  PostsByTagPage,
  PostsListPage,
  SinglePostPage,
} from "components/pages/Blog";
import PartnersPage from "components/pages/Partners/PartnersPage";
import { TermsPage } from "components/pages/Policy";
import { ProfilePage, ProfilesPage } from "components/pages/Profile";
import { SendGA4, SendYA } from "components/utils/Analytics/Analytics";
import { isDevelopment } from "components/utils/Utils";
import { lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import AppLayout from "./AppLayout";

const NotFoundPageLazy = lazy(() =>
  import("components/pages/NotFound/NotFoundPage")
);

const ContactsPageLazy = lazy(() =>
  import("components/pages/Contact/ContactsPage")
);
const TypographyPageLazy = lazy(() =>
  import("components/pages/Typography/SectionTypography")
);

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics - Send pageview with a custom path
    //ReactGA.set({ page: location.pathname });
    //ReactGA.pageview(location.pathname + location.search);
    SendGA4(location.pathname + location.search);

    // Yandex Analytics - Send pageview with a custom path
    SendYA(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route path="/countdown" element={<CountDownPage />} />
      {/*<Route path="/login" element={<AuthPage />} />*/}
      {/*<Route path="/register" element={<RegisterPage />} />*/}
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/forgot-password" element={<ForgotPage />} />

      <Route path="/" element={<AppLayout />}>
        <Route index element={<HomePage />} />

        <Route path="/home" element={<HomePage />} />
        <Route path="/contacts" element={<ContactsPageLazy />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/partners" element={<PartnersPage />} />

        <Route path="/archive" element={<ProductPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/services/scanning" element={<ScanningPage />} />

        <Route path="/policy">
          <Route index element={<TermsPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="pay" element={<PayPage />} />
        </Route>

        <Route path="/search" element={<SearchPage />} />

        <Route path="/blog" element={<BlogLayout />}>
          <Route index element={<PostsListPage />} />
          <Route path=":slug" element={<SinglePostPage />} />
          <Route path="category/:slug" element={<PostsByCategoryPage />} />
          <Route path="tag/:slug" element={<PostsByTagPage />} />
        </Route>

        {isDevelopment && (
          <Route path="/typography" element={<TypographyPageLazy />} />
        )}

        <Route path="/admin" element={<AdminPage />} />

        <Route path="/profile" element={<ProfilesPage />}>
          <Route path=":slug" element={<ProfilePage />} />
        </Route>

        {/* Catch all route */}
        <Route path="*" element={<NotFoundPageLazy />} status={404} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;

