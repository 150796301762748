import axios from "axios";
import { useEffect, useState } from "react";
import { _debug } from "utils";

// NOTE : https://gist.github.com/kettanaito/d93c6bb6b5c005656a46ee283cd8af7f
// NOTE : https://onur.dev/writing/useFetch-react-hook

const useFetch = (
  initialUrl,
  initialParams = {},
  skip = false,
  headers = {}
) => {
  const [url, updateUrl] = useState(initialUrl);
  const [params, updateParams] = useState(initialParams);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchIndex, setRefetchIndex] = useState(0);

  const queryString = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  const refetch = () =>
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);

  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      if (skip) return;

      setIsLoading(true);
      setData(null);
      setHasError(false);
      setErrorMessage("");

      console.log(queryString);

      const source = axios.CancelToken.source();
      axios
        .get(`${url}${queryString}`, {
          cancelToken: source.token,
          headers: { ...headers, "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          if (res) {
            _debug("Fetched data: ", res.data);
            //checking for multiple responses for more flexibility
            //with the url we send in.
            //res.data.content && setData(res.data.content);
            setData(res.data);
            //res.content && setData(res.content);
            //console.log(data);
          } else {
            setHasError(true);
            setErrorMessage(res.data);
          }
        })
        .catch((err) => {
          _debug(err);
          setHasError(true);
          setErrorMessage(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, [url, params, refetchIndex, queryString, skip]);

  return {
    data,
    isLoading,
    hasError,
    errorMessage,
    updateUrl,
    updateParams,
    refetch,
  };
};

export default useFetch;
