import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiCheckDouble } from "react-icons/bi";
import FormAdditionalInfo from "./CTA/FormAdditionalInfo";
import { PageSection, SectionHeader } from "./CommonSection";

// NOTE : https://bootstrapmade.com/demo/Logis/

const msgSubmit = "Подробнее";

const SectionItem = (props) => {
  const { data, index } = props;
  const isStyle2 = index % 2 === 0;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const SectionItemModalForm = ({ data }) => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="additional-info-form-modal"
        size="lg"
        aria-labelledby="additional-info-form-modal-title"
      >
        <Modal.Dialog>
          <FormAdditionalInfo
            data={data}
            title={data.title}
            onSubmit={handleClose}
            handleClose={handleClose}
          />
        </Modal.Dialog>
      </Modal>
    );
  };

  const SectionItemBody = ({ data }) => {
    const SectionListItem = () => {
      return (
        <ul className="mb-2">
          {data.list.items.map((itemList, indexList) => {
            if (!itemList.description) return null;
            return (
              <li key={indexList}>
                <i>
                  <BiCheckDouble />
                </i>
                {itemList.description}
              </li>
            );
          })}
        </ul>
      );
    };

    const SectionControlItem = ({ data }) => {
      return (
        <div className="control-wrapper mt-3 text-center">
          <button
            onClick={handleShow}
            className="btn btn-primary theme-btn"
            aria-label="Show more"
          >
            {data.msgSubmit || msgSubmit}
          </button>
        </div>
      );
    };

    return (
      <Fragment>
        <h3>{data.title}</h3>
        {data.description ? (
          <p>{data.description}</p>
        ) : (
          <Fragment>
            <p className="fst-italic">{data.description1}</p>
            <p>{data.description2}</p>
          </Fragment>
        )}
        {data.list.items && data.list.items.length > 0 && <SectionListItem />}
        {data.control && (
          <Fragment>
            <SectionControlItem data={data.control} />
            <SectionItemModalForm data={data.control} />
          </Fragment>
        )}
      </Fragment>
    );
  };

  const SectionItemImage = ({ data }) => {
    return (
      <img
        src={data.image}
        className="img-fluid img-radius"
        alt={data.imageAlt}
        width="600"
        height="600"
        loading="lazy"
      />
    );
  };

  // className="row gy-4 align-items-center features-item aos-animate"

  return (
    <Fragment>
      {isStyle2 ? (
        <div
          className="row gy-5 align-items-center features-item aos-animate"
          data-aos="fade-up"
        >
          <div className="col-md-5 col-12">
            <SectionItemImage data={data} />
          </div>
          <div className="col-md-7 col-12">
            <SectionItemBody data={data} />
          </div>
        </div>
      ) : (
        <div
          className="row gy-4 align-items-center features-item aos-animate"
          data-aos="fade-up"
        >
          <div className="col-md-5 order-1 order-md-2 col-12">
            <SectionItemImage data={data} />
          </div>
          <div className="col-md-7 order-2 order-md-1 col-12">
            <SectionItemBody data={data} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      {data.items.map((item, index) => {
        return <SectionItem data={item} index={index} key={index} />;
      })}
    </Fragment>
  );
};

/**
 * Component for showing Section "Overview2" of the site.
 *
 * @component
 */
const Overview2 = (props) => {
  const { data, id = "overview2", cn = "pt-120 pb-80" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

Overview2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Overview2;

/*
      <div
        className="row gy-4 align-items-center features-item aos-init aos-animate"
        data-aos="fade-up"
      >
        <div className="col-md-5">
          <img src="assets/img/features-3.jpg" className="img-fluid" alt="" />
        </div>
        <div className="col-md-7">
          <h3>
            Sunt consequatur ad ut est nulla consectetur reiciendis animi
            voluptas
          </h3>
          <p>
            Cupiditate placeat cupiditate placeat est ipsam culpa. Delectus quia
            minima quod. Sunt saepe odit aut quia voluptatem hic voluptas dolor
            doloremque.
          </p>
          <ul>
            <li>
              <i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex
              ea commodo consequat.
            </li>
            <li>
              <i className="bi bi-check"></i> Duis aute irure dolor in
              reprehenderit in voluptate velit.
            </li>
            <li>
              <i className="bi bi-check"></i> Facilis ut et voluptatem aperiam.
              Autem soluta ad fugiat.
            </li>
          </ul>
        </div>
      </div>

      <div
        className="row gy-4 align-items-center features-item aos-init aos-animate"
        data-aos="fade-up"
      >
        <div className="col-md-5 order-1 order-md-2">
          <img src="assets/img/features-4.jpg" className="img-fluid" alt="" />
        </div>
        <div className="col-md-7 order-2 order-md-1">
          <h3>
            Quas et necessitatibus eaque impedit ipsum animi consequatur
            incidunt in
          </h3>
          <p className="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum
          </p>
        </div>
      </div>
*/
