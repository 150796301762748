import { BiCapsule } from "react-icons/bi";
import {
  TfiDashboard,
  TfiInfinite,
  TfiReload,
  TfiServer,
  TfiShortcode,
} from "react-icons/tfi";

export const dataServiceProductPage = {
  title: "Основные преимущества",
  subtitle: "Основные преимущества",
  description: "Основные преимущества электронного архива ELARCH",
  items: [
    {
      title: "Безопасность и надежное хранение",
      subtitle: "",
      description:
        "Безопасность и надежное хранение неограниченного объема данных, быстрый поиск и удобная работа с информацией",
      image: null,
      icon: <BiCapsule />,
    },
    {
      title: "Современная архитектура",
      subtitle: "",
      description:
        "Современная архитектура, кроссплатформенность и масштабируемость позволяют быстро и эффективно внедрять высокопроизводительные решения в любой ИТ-среде",
      image: null,
      icon: <TfiShortcode />,
    },
    {
      title: "Средства интеграции с внешними системами",
      subtitle: "",
      description:
        "Средства интеграции с внешними системами с использованием SOAP-сервисов и модулей-адаптеров к внешним информационным системам (ERP, СЭД, CRM, порталам и т.д.)",
      image: null,
      icon: <TfiReload />,
    },
    {
      title: "Версионность документов и справочников",
      subtitle: "",
      description:
        "Версионность документов и справочников. Сохранение исторической информации и возможность поиска с учетом сохраненных версий",
      image: null,
      icon: <TfiServer />,
    },
    {
      title: "Поддержка любых баз данных MS SQL, Oracle, PostgreSQL и других",
      subtitle: "",
      description:
        "Поддержка любых баз данных MS SQL, Oracle, PostgreSQL и других",
      image: null,
      icon: <TfiInfinite />,
    },
    {
      title: "Непревзайденная производительность",
      subtitle: "",
      description:
        // Модули для расширения функционала системы: конструктор отчетов, модуль автоматизации бизнес-процессов, внеофисное хранение, учет бумажных документов и другие
        "Секторное распределение данных с постоянной репликацией хранения позволяет выдерживать высочайшие нагрузки без потери производительности и скорости доступа к информации",
      image: null,
      icon: <TfiDashboard />,
    },
  ],
};

export const dataServiceAboutPage = {
  title: "Комплекс услуг по сканированию и распознаванию",
  subtitle: "Услуги",
  description:
    " At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
  items: [
    {
      title: "Подготовительные работы",
      subtitle: "",
      description:
        "Разброшюровка, маркировка, создание описи дел, нанесение штрих-кодов",
      image: null,
      icon: <BiCapsule />,
    },
    {
      title: "Сканирование",
      subtitle: "",
      description: "Перевод бумажного документа в электронный вид",
      image: null,
      icon: <TfiShortcode />,
    },
    {
      title: "Текстовое распознование",
      subtitle: "",
      description:
        "Перевод графических образов в редактируемый (машинописный) вид для быстрого поиска слова или значения в тексте сканированного документа",
      image: null,
      icon: <TfiReload />,
    },
    {
      title: "Индексирование данных",
      subtitle: "",
      description:
        "Обработка документов по заданному составу полей является ключевым, если требуется осуществлять поиск документов по различным параметрам",
      image: null,
      icon: <TfiServer />,
    },
    {
      title: "Формирование электронных книг",
      subtitle: "",
      description:
        "Создание удобных и привычных для использования документов офисного типа (PDF, DOC)",
      image: null,
      icon: <TfiInfinite />,
    },
    {
      title: "Экспорт данных",
      subtitle: "",
      description:
        'Загрузка данных в электронный архив на базе решений "ELARCH" или корпоративную информационную систему',
      image: null,
      icon: <TfiDashboard />,
    },
  ],
};
