import { Banner } from "components/sections";
import { dataSerachPage } from "data";
import { Fragment } from "react";
import SearchSection from "./SearchSection";

/**
 * Component for showing Page "Serach" of the site.
 *
 * @component
 */
const SearchPage = () => {
  return (
    <Fragment>
      <main className="page-search" id="main">
        <Banner data={dataSerachPage} />
        <SearchSection />
      </main>
    </Fragment>
  );
};

export default SearchPage;
