import {
  CallToAction,
  Feature,
  FeatureRound,
  OurProducts,
  SectionSlogan,
  Service,
  Subscribe2,
} from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataBlog,
  dataCTALetsContact,
  dataFAQ,
  dataFeature,
  dataFeature3,
  dataHeroMainPage as dataHero,
  dataHomePage,
  dataOurProductsMainPage as dataOurProducts,
  dataOverviewMainPage as dataOverview,
  dataServiceMainPage as dataService,
  dataServicesInfoMainPage,
  dataSloganMainPage as dataSlogan,
  dataSubscribe,
  ldJson,
} from "data";
import React, { Fragment, useRef } from "react";
import Overview2 from "../../sections/Overview2";
import Hero from "./Hero";
import ServicesInfo from "./ServicesInfo";

const BlogTopPosts = React.lazy(() => import("../Blog/BlogTopPosts"));
const SectionFAQ = React.lazy(() => import("./FAQ"));
//const Pricing = React.lazy(() => import("../../sections/Pricing"));
//const Process = React.lazy(() => import("../../sections/Process"));
//const Service = React.lazy(() => import("../../sections/Service"));
//const FeatureTabs = React.lazy(() => import("../../sections/FeatureTabs"));

// NOTE : https://demo.graygrids.com/themes/space/index.html
// NOTE : https://demo.graygrids.com/themes/digimark/services.html
// NOTE : https://www.sleepinggiantmedia.co.uk/posts/canonical-tags-implementation-guide/

// NOTE : https://elar-context.ru

// NOTE : https://scrapfly.io/blog/search-engine-using-web-scraping/

/**
 * Component for showing Page "Home" of the site.
 *
 * @component
 */
const HomePage = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataHomePage.meta}>
        {/*Only canonicalise pages which are a duplicate or are near-identical.*/}
        <link rel="canonical" href="https://elarch.ru/" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </SEO>
      <main className="page-main" id="top">
        <Hero data={dataHero} onScroll={executeScroll} />
        <Feature data={dataFeature} type="style1" />
        <Overview2 data={dataOverview} cn="pt-120 pb-120" />
        <SectionSlogan data={dataSlogan} />
        <FeatureRound data={dataFeature3} />
        {/* <Feature data={dataFeature2} type="style2" /> */}
        {/* <About data={dataAbout} type="style2" /> */}
        {/* <FeatureTabs data={dataFeatureTabs} /> */}
        <ServicesInfo data={dataServicesInfoMainPage} />
        {/* <Testimonial data={dataTestimonial} /> */}
        {/* <Pricing data={dataPricing} /> */}
        <Service data={dataService} type="style2" />
        <OurProducts data={dataOurProducts} />
        <CallToAction data={dataCTALetsContact} innerRef={refCAT} />
        <SectionFAQ data={dataFAQ} />
        <BlogTopPosts data={dataBlog} />
        <Subscribe2 data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default HomePage;
