import {
  CallToAction,
  Feature,
  FeatureTabs,
  OurProducts,
  Overview2,
  Process,
  SectionSlogan,
  Testimonial,
} from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataCTALetsContact,
  dataFAQ,
  dataFeatureProductPage as dataFeature,
  dataFeatureTabsProductPage as dataFeatureTabs,
  dataHeroProductPage as dataHero,
  dataOurProductsMainPage as dataOurProducts,
  dataOverviewProductPage as dataOverview,
  dataProcessProductPage as dataProcess,
  dataProductPage,
  dataServiceProductPage as dataService,
  dataSloganMainPage as dataSlogan,
  dataSubscribe,
  dataTestimonial,
} from "data";
import { Fragment, useRef } from "react";
import Service from "../../sections/Service";
import { SectionFAQ, Subscribe } from "../Home";
import Hero2 from "./Hero2";

//const Pricing = React.lazy(() => import("./Pricing"));
//const Process = React.lazy(() => import("../../sections/Process"));
//const Service = React.lazy(() => import("../../sections/Service"));
//const FeatureTabs = React.lazy(() => import("../../sections/FeatureTabs"));

/**
 * Component for showing Page "Product" of the site.
 *
 * @component
 */
const ProductPage = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataProductPage.meta}>
        <link rel="canonical" href="https://elarch.ru/archive/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-product" id="main">
        {/*<Hero data={dataHero} />*/}
        <Hero2 data={dataHero} />
        <Feature data={dataFeature} type="style1" />
        <Overview2 data={dataOverview} cn="pt-120 pb-120" />
        <SectionSlogan data={dataSlogan} />
        {/*<ArchSection data={dataArchSection} />*/}
        {/*<Overview data={data2} />*/}
        <Process data={dataProcess} />
        <FeatureTabs data={dataFeatureTabs} />
        <Testimonial data={dataTestimonial} />
        {/* <Pricing data={dataPricing} />*/}
        <Service data={dataService} />
        <OurProducts data={dataOurProducts} />
        <CallToAction data={dataCTALetsContact} innerRef={refCAT} />
        <SectionFAQ data={dataFAQ} />
        <Subscribe data={dataSubscribe} />
      </main>
    </Fragment>
  );
};

export default ProductPage;
