import { FaGift } from "react-icons/fa6";
import { GoFlame, GoMortarBoard, GoRocket, GoSearch } from "react-icons/go";

/**
 * Data of page "Partners" page of the site.
 *
 *
 */
export const dataHeroPartnersPage = {
  title: "Присоединяйтесь к партнёрской программе ElArch",
  subtitle:
    //  "Выберите удобную форму партнерства и зарабатывайте вместе с нами. Присоединяйтесь к партнерской программе прямо сейчас!",
    "Выберите удобную форму партнерства и зарабатывайте вместе с нами, присоединяйтесь прямо сейчас!",
  description:
    "Рекомендуйте электронный архив ElArch для организаций и зарабатывайте до 40% от платежей клиентов и 50% от стоимости внедрения",
  items: [],
  image: require("assets/images/hero/hero-img (1).webp"),
  imageAlt: "Выберите удобную форму партнерства и зарабатывайте вместе с нами",
};

export const dataFeaturePartnersPage = {
  title: "Если вы занимаетесь",
  subtitle: "Если вы занимаетесь",
  description: "Если вы занимаетесь",
  items: [
    {
      title: "Разработкой ПО и ИТ-консалтингом",
      subtitle: "",
      description:
        "увеличивайте чек за счет продажи дополнительного софта - сервиса электронного архива организации",
      icon: <FaGift />,
      //image: Image1,
      image: null,
      imageAlt: "",
    },
    {
      title: "Бухучетом и оказываете аудиторские услуги",
      subtitle: "",
      description: "Повышайте эффективность своих услуг за счет сервиса ЭДО",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Работаете с технической и конструкторской документацией",
      subtitle: "",
      description:
        "Зарабатывайте на внедрении сервиса электронного архива в свои проекты",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataFeature2PartnersPage = {
  title: "Стартуйте уже сегодня",
  subtitle: "ДСтартуйте уже сегодня",
  description: "Стартуйте уже сегодня",
  items: [
    {
      title: "Быстро начать",
      subtitle: "",
      description:
        "Вы получите готовые скрипты, обучающие материалы о наших продуктах и нашу поддержку в ходе сделки",
      icon: <FaGift />,
      //image: Image1,
      image: null,
      imageAlt: "",
    },
    {
      title: "Легко продавать",
      subtitle: "",
      description:
        "Мы расчитываем, что к 2025 году КЭДО станет обязательным и электронные архивы станут необходимым сервисом",
      // "Оцифровка документов перед сдачей документов в архив обеспечивает быстрый доступ к данным в любой момент, когда они могут понадобиться."
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Выгодно сотрудничать",
      subtitle: "",
      description:
        "Получайте процент за продажу, внедрение и продление лицензий клиентами",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataProcessPartnersPage = {
  title: "Давайте зарабатывать вместе",
  subtitle: "Процесс получения партнерского статуса",
  description: "Процесс получения партнерского статуса",
  items: [
    {
      title: "Регистрируетесь в партнёрской программе ElArch",
      subtitle: "",
      description:
        "Регистрируетесь в партнёрской программе ElArch на сайте или через нашего менеджера.",
      image: require("assets/images/timeline/timeline2-1.webp"),
      imageAlt: "",
      icon: <GoFlame />,
    },
    {
      title: "Приводите клиента",
      subtitle: "",
      description: "Рескажите о нашем продукте свом клиентам и партнерам.",
      image: require("assets/images/timeline/timeline2-2.webp"),
      imageAlt: "",
      icon: <GoSearch />,
    },
    {
      title: "Презентуйте продукт и завершите сделку",
      subtitle: "",
      description:
        "Наш менеджер поможет вам презентовать продукт и завершить сделку.",
      image: require("assets/images/timeline/timeline2-3.webp"),
      imageAlt: "",
      icon: <GoMortarBoard />,
    },
    {
      title: "Вознаграждение",
      subtitle: "",
      description:
        "Вы получаете вознаграждение за все платежи по зарегистрированным от вас клиентам. Отслеживайте ваши сделки и прибыль в личном кабинете.",
      image: require("assets/images/timeline/timeline2-4.webp"),
      imageAlt: "",
      icon: <GoRocket />,
    },
  ],
};

export const dataPricingPartnersPage = {
  title: "Варианты сотрудничества",
  subtitle: "Варианты сотрудничества",
  description: "Выберите интересующий вас вариант сотрудничества",
  description1:
    "* новый клиент - юридическое лицо, не использующее ранее сервис ElArch, оплатившее лицензию по договору с ElArch",

  items: [
    {
      title: "Реферальный партнер",
      subtitle: "Рекомендуйте Elarch друзьям, клиентам и партнерам",
      showSubtitle: true,
      price: 0.0,
      showPrice: false,
      description: "",
      msg: {
        msgPurchase: "Стать партнером",
      },

      items: [
        { title: "15% от суммы сделки с клиентом" },
        { title: "5% от суммы по продлению" },
        { title: " - " },
        { title: " - " },
        { title: "Презентационные материалы" },
        { title: "Без обучения" },
      ],
    },
    {
      title: "Бизнес-партнер",
      subtitle: "Привлекайте от 3-х новых клиентов в квартал",
      showSubtitle: true,
      price: 0.0,
      showPrice: false,
      description: "",
      msg: {
        msgPurchase: "Стать партнером",
      },

      items: [
        { title: "30% от суммы сделки с клиентом" },
        { title: "10% от суммы по продлению" },
        { title: "50% от стоимости внедрения" },
        { title: " - " },
        { title: "Презентационные материалы" },
        { title: "Без обучения" },
      ],
    },
    {
      title: "Генеральный партнер",
      subtitle: "Привлекайте от 5-х новых клиентов в квартал",
      showSubtitle: true,
      price: 0.0,
      showPrice: false,
      description: "",
      msg: {
        msgPurchase: "Стать партнером",
      },

      items: [
        { title: "40% от суммы сделки с клиентом" },
        { title: "20% от суммы по продлению" },
        { title: "50% от стоимости внедрения" },
        { title: " - " },
        { title: "Презентационные материалы" },
        { title: "Обязательное обучение" },
      ],
    },
  ],
};
