import AOS from "aos";
import { Footer, TopMenu } from "components/layout";
import ScrollToTop from "components/utils/ScrollHandler/ScrollToTop";
import { USE_AOS } from "config/config";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";

import "aos/dist/aos.css";
import { CookiePolicy } from "components/sections/TypicalSections";

const AppLayout = () => {
  const location = useLocation();

  useEffect(() => {
    if (USE_AOS) {
      AOS.init({ duration: 2000, disable: "mobile" });
      //AOS.refresh();
    }
  }, []);

  useEffect(() => {
    if (USE_AOS) AOS.refresh();
  }, [location]);

  //

  return (
    <div className="layout-app">
      <ScrollToTop top={500} />
      <CookiePolicy />
      <TopMenu />
      <div className="layout-page">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default AppLayout;
