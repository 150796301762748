import { FAQAccordion } from "components/sections";
import { useMediaQuery } from "hooks";
import { Fragment } from "react";
import { PageSection, SectionHeader } from "../Home";

const SectionContent = (props) => {
  const isWideScreen = useMediaQuery(1400);

  console.log(isWideScreen);

  const { data } = props;
  return (
    <div className="row justify-content-center">
      {isWideScreen ? (
        <div className="col-lg-8 col-md-offset-2 faq-right-collapse">
          <FAQAccordion data={data} showMain={false} />
        </div>
      ) : (
        <Fragment>
          <div className="col-md-6 faq-right-collapse">
            <FAQAccordion data={data} showMain={false} screenCol={1} />
          </div>
          <div className="col-md-6 faq-right-collapse">
            <FAQAccordion data={data} showMain={false} screenCol={2} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

/**
 * Component for showing Section "SectionFAQ" of the site.
 *
 * @component
 */
const SectionFAQ = (props) => {
  const { data, id = "faq", cn = "pt-140 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

export default SectionFAQ;
