import { sendToAnalytics } from "components/utils/Analytics";
import { createRoot, hydrateRoot } from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";

const domNode = document.getElementById("root");

if (domNode.hasChildNodes()) {
  const root = hydrateRoot(domNode, <App />);
} else {
  const root = createRoot(domNode);
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);

