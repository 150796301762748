import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataMetaBlankPage, dataTypographyPage } from "data";
import { Fragment } from "react";
import SectionTypography from "./SectionTypography";

/**
 * Component for showing Page "Typography" of the site.
 *
 * @component
 */
const TypographyPage = () => {
  return (
    <Fragment>
      <SEO data={dataMetaBlankPage}>
        <link rel="canonical" href="https://elarch.ru/typography/" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-typography">
        <Banner data={dataTypographyPage} />
        <SectionTypography />
      </main>
    </Fragment>
  );
};

export default TypographyPage;
