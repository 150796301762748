import { isDevelopment } from "components/utils/Utils";
import { API_URI } from "config/config";
import { Fragment, useRef, useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";

import { useFetch } from "hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./ProfilePage.scss";

// NOTE : https://www.bootdey.com/snippets/view/bs5-profile-billing-page

const ContentProfileTab = (props) => {
  const { data, slug } = props;

  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  const handleImageUpload = async (e) => {
    const uploadFile = e.target.files[0];
    console.log(uploadFile);
    if (uploadFile) {
      const photoURL = URL.createObjectURL(uploadFile);
      // dispatch({type:  "UPDATE_PROFILE", payload : {...profile, file, photoURL}})

      //const updatedFiles = file;
      const { name, file } = uploadFile;
      console.log(name, file, photoURL);
      try {
        const imageName = uuidv4() + "." + file?.name?.split(".")?.pop();
        console.log(imageName);

        const uploadData = new FormData();
        uploadData.append("file", uploadFile, uploadFile.name);
        //uploadData.append("name", imageName);
        //http://localhost:8080/api/user/3/image/upload
        fetch(`${API_URI}/user/3/image/upload`, {
          method: "POST",
          body: uploadData,
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJsb3Zrb3ZhQG1haWwucnUiLCJpYXQiOjE3MDE3MzgzNjAsImV4cCI6MTcwMTgyNDc2MH0.X1jHeCHV2lRoEFJhrAeV0cFa2YoK1E4Rup6MbOinDqc",
          },
        });

        //const photoURL = await uploadFile(file, profile);
      } catch (err) {
        console.error(err);
      }

      //const reader = new FileReader();
      //const { current } = uploadedImage;
      //current.file = file;
      //reader.onload = (e) => {
      //  current.src = e.target.result;
      //};
      //reader.readAsDataURL(file);
    }
  };

  return (
    <div className="container-xl px-4 mt-4">
      <hr className="mt-0 mb-4" />
      <div className="row">
        <div className="col-xl-4">
          <div className="card mb-4  /mb-xl-0">
            <div className="card-header">Profile Picture</div>
            <div className="card-body text-center">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />

              <img
                ref={uploadedImage}
                className="img-account-profile rounded-circle mb-2"
                src={`${API_URI}/user/${slug}/image/download`}
                alt=""
              />

              <div className="small font-italic text-muted mb-4">
                JPG or PNG no larger than 5 MB
              </div>

              <Button
                variant="primary"
                className="btn"
                type="button"
                onClick={() => imageUploader.current.click()}
              >
                Upload new image
              </Button>
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="card mb-4">
            <div className="card-header">Account Details</div>
            <div className="card-body">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="profileForm.inputUsername"
                >
                  <Form.Label className="small mb-1">
                    Username (how your name will appear to other users on the
                    site)
                  </Form.Label>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Enter your username"
                    value={data.username}
                  />
                </Form.Group>

                <Row className="gx-3 mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.inputFirstName"
                    as={Col}
                  >
                    <Form.Label className="small mb-1">First name</Form.Label>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Enter your first name"
                      value={data.firstName}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.inputLastName"
                    as={Col}
                  >
                    <Form.Label className="small mb-1">Last name</Form.Label>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Enter your last name"
                      value={data.lastName}
                    />
                  </Form.Group>
                </Row>

                <Row className="gx-3 mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.inputOrgName"
                    as={Col}
                  >
                    <Form.Label className="small mb-1">
                      Organization name
                    </Form.Label>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Enter your organization name"
                      value={data.organisation}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.inputLocation"
                    as={Col}
                  >
                    <Form.Label className="small mb-1">Location</Form.Label>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Enter your location"
                      value={data.location}
                    />
                  </Form.Group>
                </Row>

                <Form.Group
                  className="mb-3"
                  controlId="profileForm.inputEmailAddress"
                >
                  <Form.Label className="small mb-1">Email address</Form.Label>
                  <Form.Control
                    className=""
                    type="email"
                    placeholder="Enter your email address"
                    value="name@example.com"
                  />
                </Form.Group>

                <Row className="gx-3 mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.inputPhone"
                    as={Col}
                  >
                    <Form.Label className="small mb-1">Phone number</Form.Label>
                    <Form.Control
                      className=""
                      id="inputPhone"
                      type="tel"
                      placeholder="Enter your phone number"
                      value={data.phone}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.inputBirthday"
                    as={Col}
                  >
                    <Form.Label className="small mb-1">Birthday</Form.Label>
                    <Form.Control
                      className=""
                      id="inputBirthday"
                      type="text"
                      name="birthday"
                      placeholder="Enter your birthday"
                      value={data.birthday}
                    />
                  </Form.Group>
                </Row>

                <Button variant="primary" className="btn" type="button">
                  Save changes
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContentSecurityTab = (props) => {
  const { data } = props;
  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="card mb-4">
          <div className="card-header">Change Password</div>
          <div className="card-body">
            <form>
              <div className="mb-3">
                <label className="small mb-1" for="currentPassword">
                  Current Password
                </label>
                <input
                  className="form-control"
                  id="currentPassword"
                  type="password"
                  placeholder="Enter current password"
                />
              </div>

              <div className="mb-3">
                <label className="small mb-1" for="newPassword">
                  New Password
                </label>
                <input
                  className="form-control"
                  id="newPassword"
                  type="password"
                  placeholder="Enter new password"
                />
              </div>

              <div className="mb-3">
                <label className="small mb-1" for="confirmPassword">
                  Confirm Password
                </label>
                <input
                  className="form-control"
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm new password"
                />
              </div>
              <button
                className="btn btn-primary theme-btn mx-auto"
                type="button"
              >
                Save
              </button>
            </form>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-header">Security Preferences</div>
          <div className="card-body">
            <h5 className="mb-1">Account Privacy</h5>
            <p className="small text-muted">
              By setting your account to private, your profile information and
              posts will not be visible to users outside of your user groups.
            </p>
            <form>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="radioPrivacy1"
                  type="radio"
                  name="radioPrivacy"
                  checked=""
                />
                <label className="form-check-label" for="radioPrivacy1">
                  Public (posts are available to all users)
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="radioPrivacy2"
                  type="radio"
                  name="radioPrivacy"
                />
                <label className="form-check-label" for="radioPrivacy2">
                  Private (posts are available to only users in your groups)
                </label>
              </div>
            </form>
            <hr className="my-4" />

            <h5 className="mb-1">Data Sharing</h5>
            <p className="small text-muted">
              Sharing usage data can help us to improve our products and better
              serve our users as they navigation through our application. When
              you agree to share usage data with us, crash reports and usage
              analytics will be automatically sent to our development team for
              investigation.
            </p>
            <form>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="radioUsage1"
                  type="radio"
                  name="radioUsage"
                  checked=""
                />
                <label className="form-check-label" for="radioUsage1">
                  Yes, share data and crash reports with app developers
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="radioUsage2"
                  type="radio"
                  name="radioUsage"
                />
                <label className="form-check-label" for="radioUsage2">
                  No, limit my data sharing with app developers
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card mb-4">
          <div className="card-header">Two-Factor Authentication</div>
          <div className="card-body">
            <p>
              Add another level of security to your account by enabling
              two-factor authentication. We will send you a text message to
              verify your login attempts on unrecognized devices and browsers.
            </p>
            <form>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="twoFactorOn"
                  type="radio"
                  name="twoFactor"
                  checked=""
                />
                <label className="form-check-label" for="twoFactorOn">
                  On
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="twoFactorOff"
                  type="radio"
                  name="twoFactor"
                />
                <label className="form-check-label" for="twoFactorOff">
                  Off
                </label>
              </div>
              <div className="mt-3">
                <label className="small mb-1" for="twoFactorSMS">
                  SMS Number
                </label>
                <input
                  className="form-control"
                  id="twoFactorSMS"
                  type="tel"
                  placeholder="Enter a phone number"
                  value="555-123-4567"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-header">Delete Account</div>
          <div className="card-body">
            <p>
              Deleting your account is a permanent action and cannot be undone.
              If you are sure you want to delete your account, select the button
              below.
            </p>
            <button className="btn btn-danger-soft text-danger" type="button">
              I understand, delete my account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContnentNotificationTab = (props) => {
  const { data } = props;
  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="card card-header-actions mb-4">
          <div className="card-header">
            Email Notifications
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                id="flexSwitchCheckChecked"
                type="checkbox"
                checked=""
              />
              <label
                className="form-check-label"
                for="flexSwitchCheckChecked"
              ></label>
            </div>
          </div>
          <div className="card-body">
            <form>
              <div className="mb-3">
                <label className="small mb-1" for="inputNotificationEmail">
                  Default notification email
                </label>
                <input
                  className="form-control"
                  id="inputNotificationEmail"
                  type="email"
                  value="name@example.com"
                  disabled=""
                />
              </div>

              <div className="mb-0">
                <label className="small mb-2">
                  Choose which types of email updates you receive
                </label>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkAccountChanges"
                    type="checkbox"
                    checked=""
                  />
                  <label className="form-check-label" for="checkAccountChanges">
                    Changes made to your account
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkAccountGroups"
                    type="checkbox"
                    checked=""
                  />
                  <label className="form-check-label" for="checkAccountGroups">
                    Changes are made to groups you're part of
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkProductUpdates"
                    type="checkbox"
                    checked=""
                  />
                  <label className="form-check-label" for="checkProductUpdates">
                    Product updates for products you've purchased or starred
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkProductNew"
                    type="checkbox"
                    checked=""
                  />
                  <label className="form-check-label" for="checkProductNew">
                    Information on new products and services
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkPromotional"
                    type="checkbox"
                  />
                  <label className="form-check-label" for="checkPromotional">
                    Marketing and promotional offers
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="checkSecurity"
                    type="checkbox"
                    checked=""
                    disabled=""
                  />
                  <label className="form-check-label" for="checkSecurity">
                    Security alerts
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card card-header-actions mb-4">
          <div className="card-header">
            Push Notifications
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                id="smsToggleSwitch"
                type="checkbox"
                checked=""
              />
              <label className="form-check-label" for="smsToggleSwitch"></label>
            </div>
          </div>
          <div className="card-body">
            <form>
              <div className="mb-3">
                <label className="small mb-1" for="inputNotificationSms">
                  Default SMS number
                </label>
                <input
                  className="form-control"
                  id="inputNotificationSms"
                  type="tel"
                  value="123-456-7890"
                  disabled=""
                />
              </div>

              <div className="mb-0">
                <label className="small mb-2">
                  Choose which types of push notifications you receive
                </label>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkSmsComment"
                    type="checkbox"
                    checked=""
                  />
                  <label className="form-check-label" for="checkSmsComment">
                    Someone comments on your post
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkSmsShare"
                    type="checkbox"
                  />
                  <label className="form-check-label" for="checkSmsShare">
                    Someone shares your post
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkSmsFollow"
                    type="checkbox"
                    checked=""
                  />
                  <label className="form-check-label" for="checkSmsFollow">
                    A user follows your account
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    id="checkSmsGroup"
                    type="checkbox"
                  />
                  <label className="form-check-label" for="checkSmsGroup">
                    New posts are made in groups you're part of
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="checkSmsPrivateMessage"
                    type="checkbox"
                    checked=""
                  />
                  <label
                    className="form-check-label"
                    for="checkSmsPrivateMessage"
                  >
                    You receive a private message
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card">
          <div className="card-header">Notification Preferences</div>
          <div className="card-body">
            <form>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  id="checkAutoGroup"
                  type="checkbox"
                  checked=""
                />
                <label className="form-check-label" for="checkAutoGroup">
                  Automatically subscribe to group notifications
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  id="checkAutoProduct"
                  type="checkbox"
                />
                <label className="form-check-label" for="checkAutoProduct">
                  Automatically subscribe to new product notifications
                </label>
              </div>

              <button className="btn btn-danger-soft text-danger">
                Unsubscribe from all notifications
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContentBillingTab = (props) => {
  const { data } = props;
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-4 mb-4">
          <div className="card h-100 border-start-lg border-start-primary">
            <div className="card-body">
              <div className="small text-muted">Current monthly bill</div>
              <div className="h3">$20.00</div>
              <a className="text-arrow-icon small" href="#!">
                Switch to yearly billing
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <div className="card h-100 border-start-lg border-start-secondary">
            <div className="card-body">
              <div className="small text-muted">Next payment due</div>
              <div className="h3">July 15</div>
              <a className="text-arrow-icon small text-secondary" href="#!">
                View payment history
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-4">
          <div className="card h-100 border-start-lg border-start-success">
            <div className="card-body">
              <div className="small text-muted">Current plan</div>
              <div className="h3 d-flex align-items-center">Freelancer</div>
              <a className="text-arrow-icon small text-success" href="#!">
                Upgrade plan
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-header-actions mb-4">
        <div className="card-header">
          Payment Methods
          <button className="btn btn-sm btn-primary" type="button">
            Add Payment Method
          </button>
        </div>
        <div className="card-body px-0">
          <div className="d-flex align-items-center justify-content-between px-4">
            <div className="d-flex align-items-center">
              <i className="fab fa-cc-visa fa-2x cc-color-visa"></i>
              <div className="ms-4">
                <div className="small">Visa ending in 1234</div>
                <div className="text-xs text-muted">Expires 04/2024</div>
              </div>
            </div>
            <div className="ms-4 small">
              <div className="badge bg-light text-dark me-3">Default</div>
              <a href="#!">Edit</a>
            </div>
          </div>
          <hr />

          <div className="d-flex align-items-center justify-content-between px-4">
            <div className="d-flex align-items-center">
              <i className="fab fa-cc-mastercard fa-2x cc-color-mastercard"></i>
              <div className="ms-4">
                <div className="small">Mastercard ending in 5678</div>
                <div className="text-xs text-muted">Expires 05/2022</div>
              </div>
            </div>
            <div className="ms-4 small">
              <a className="text-muted me-3" href="#!">
                Make Default
              </a>
              <a href="#!">Edit</a>
            </div>
          </div>
          <hr />

          <div className="d-flex align-items-center justify-content-between px-4">
            <div className="d-flex align-items-center">
              <i className="fab fa-cc-amex fa-2x cc-color-amex"></i>
              <div className="ms-4">
                <div className="small">American Express ending in 9012</div>
                <div className="text-xs text-muted">Expires 01/2026</div>
              </div>
            </div>
            <div className="ms-4 small">
              <a className="text-muted me-3" href="#!">
                Make Default
              </a>
              <a href="#!">Edit</a>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">Billing History</div>
        <div className="card-body p-0">
          <div className="table-responsive table-billing-history">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="border-gray-200" scope="col">
                    Transaction ID
                  </th>
                  <th className="border-gray-200" scope="col">
                    Date
                  </th>
                  <th className="border-gray-200" scope="col">
                    Amount
                  </th>
                  <th className="border-gray-200" scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#39201</td>
                  <td>06/15/2021</td>
                  <td>$29.99</td>
                  <td>
                    <span className="badge bg-light text-dark">Pending</span>
                  </td>
                </tr>
                <tr>
                  <td>#38594</td>
                  <td>05/15/2021</td>
                  <td>$29.99</td>
                  <td>
                    <span className="badge bg-success">Paid</span>
                  </td>
                </tr>
                <tr>
                  <td>#38223</td>
                  <td>04/15/2021</td>
                  <td>$29.99</td>
                  <td>
                    <span className="badge bg-success">Paid</span>
                  </td>
                </tr>
                <tr>
                  <td>#38125</td>
                  <td>03/15/2021</td>
                  <td>$29.99</td>
                  <td>
                    <span className="badge bg-success">Paid</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ProfilePage = () => {
  const { slug } = useParams();
  let [profileData, setProfileData] = useState();

  const URL = `${API_URI}/user/3`; //${slug}

  console.log(URL);

  const {
    data: userData,
    isLoading,
    hasError,
    errorMessage,
  } = useFetch(URL, {}, false, {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJsb3Zrb3ZhQG1haWwucnUiLCJpYXQiOjE3MDE3MzQzNzgsImV4cCI6MTcwMTgyMDc3OH0.Sp2Jwh0JbN9VrAwnxDuczWCdK9D265xhB6UhvJ1nnFc",
  });

  useEffect(() => {}, []);

  useEffect(() => {}, [userData]);

  useEffect(() => {
    console.log("Data fetched: ", userData);
    setProfileData(userData);
  }, [isLoading, hasError, userData]);

  //if (!profileData) {
  //  return <span>The user profile you've requested doesn't exist.</span>;
  //}

  if (isLoading || hasError) return null;

  if (!userData) return null;

  return (
    <div className="container-xl px-4 mt-4">
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="profile" title="Profile">
          <ContentProfileTab data={userData} slug={3} />
        </Tab>
        <Tab eventKey="billing" title="Billing" disabled={isDevelopment}>
          <ContentBillingTab />
        </Tab>
        <Tab eventKey="security" title="Security" disabled={isDevelopment}>
          <ContentSecurityTab />
        </Tab>
        <Tab
          eventKey="notifications"
          title="Notifications"
          disabled={isDevelopment}
        >
          <ContnentNotificationTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ProfilePage;
