import { registerOrder } from "actions/actions";
import classNames from "classnames";
import FormOrder from "components/sections/CTA/FormOrder";
import { formatPrice } from "components/utils/Utils";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { PageSection, SectionHeader } from "./CommonSection";

const msgPurchase = "Заказать";

const ContentItem = (props) => {
  const { data, cn } = props;

  return (
    <div className="col-xl-4 col-lg-4 col-md-6">
      <div className={classNames("single-pricing mb-50", cn)}>
        <h4 className="mb-3">{data.title}</h4>
        {data.showSubtitle && <p className="mb-2">{data.subtitle}</p>}
        {data.showPrice && <h3 className="mb-2">{formatPrice(data.price)}</h3>}
        <ul>
          {data.items.map((item, index) => {
            return <li key={index}>{item.title}</li>;
          })}
        </ul>
        {/*<Link to="/contacts" className="btn theme-btn">
          {msgPurchase}
        </Link>*/}
        <Button
          className="btn btn-primary theme-btn mx-auto"
          variant="primary"
          onClick={props.handleShow}
        >
          {data?.msg?.msgPurchase || msgPurchase}
        </Button>
        {data.description && <p>{data.description}</p>}
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, ...otherProps } = props;

  return (
    <Fragment>
      <div className="row mt-5">
        {data.items.map((item, index) => {
          return (
            <Fragment key={index}>
              {index === 1 ? (
                <ContentItem data={item} cn="active" {...otherProps} />
              ) : (
                <ContentItem data={item} cn="" {...otherProps} />
              )}
            </Fragment>
          );
        })}
      </div>
      {data.description1 && (
        <div className="row">
          <p className="mt-3 text-center">{data.description1}</p>
        </div>
      )}
    </Fragment>
  );
};

/**
 * Component for showing Section "Pricing" of the site.
 *
 * @component
 */
const Pricing = (props) => {
  const { data, id = "pricing", cn = "pt-100 pb-60" } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Modal1 = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="order-form-modal"
      >
        <Modal.Body>
          <div className="contact-form-wrapper">
            <FormOrder onSubmit={registerOrder} handleClose={handleClose} />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <PageSection id={id} cn={cn}>
      <Modal1 />
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent
          data={data}
          handleShow={handleShow}
          handleClose={handleClose}
        />
      </div>
    </PageSection>
  );
};

Pricing.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default Pricing;
