import { BiCoffee, BiMap, BiPhoneCall } from "react-icons/bi";
import { dataSite } from "./dataSite";

const msgContact = "Контакты";
const msgAddress = "Адрес";
const msgWorkHours = "Время работы";

export const dataContact = {
  title: "Связаться с нами",
  subtitle: "Готовы начать работу",
  description:
    " At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
  items: [
    {
      title: msgContact,
      subtitle: "",
      items: [{ title: dataSite.main.email }, { title: dataSite.main.phone }],
      icon: <BiPhoneCall />,
    },
    {
      title: msgAddress,
      subtitle: "",
      items: [{ title: dataSite.main.address }],
      icon: <BiMap />,
    },
    {
      title: msgWorkHours,
      subtitle: "",
      items: [{ title: dataSite.main.wh }, { title: dataSite.main.wh1 }],
      icon: <BiCoffee />,
    },
  ],
  extraData: {
    title: "Напишите нам",
    subtitle: "Напишите нам",
    description: "",
  },
};

export const dataContactMap = {
  title: "Как нас найти",
  subtitle: "Как нас найти",
  description: "Интерактивная карта",
  items: [
    {
      title: "",
      subtitle: "",
      items: null,
      icon: null,
    },
  ],
  extraData: {},
};
