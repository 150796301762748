import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoMailchimp,
  BiLogoTelegram,
  BiLogoTwitter,
  BiLogoVk,
  BiLogoYoutube,
} from "react-icons/bi";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa6";
import { RiGoogleFill } from "react-icons/ri";
import SocialLinks from "./SocialLinks";

export const getSocialIconByType = (type) => {
  switch (type) {
    case "tg":
      return <BiLogoTelegram />;
    case "dz":
      return <BiLogoTelegram />;
    case "fb":
      return <FaFacebookF />;
    case "tw":
      return <FaTwitter />;
    case "ln":
      return <FaLinkedin />;
    case "yt":
      return <BiLogoYoutube />;
    case "rt":
      return <BiLogoYoutube />;
    case "vk":
      return <BiLogoVk />;
    case "web":
      return <RiGoogleFill />;
    default:
      console.error("Social icon type not set: ", type);
      break;
  }
  return null;
};

export const getSocialIconByType2 = (type) => {
  switch (type) {
    case "tg":
      return <BiLogoTelegram />;
    case "dz":
      return <BiLogoTelegram />;
    case "mail":
      return <BiLogoMailchimp />;
    case "tw":
      return <BiLogoTwitter />;
    case "fb":
      return <BiLogoFacebook />;
    case "in":
      return <BiLogoInstagram />;
    case "ln":
      return <BiLogoLinkedin />;
    case "yt":
      return <BiLogoYoutube />;
    case "rt":
      return <BiLogoYoutube />;
    case "vk":
      return <BiLogoVk />;
    case "web":
      return <RiGoogleFill />;
    default:
      console.error("Social icon type not set: ", type);
      break;
  }
  return null;
};

export { SocialLinks };
