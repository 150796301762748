import { Spinner } from "components/utils/Spinner";
import { API_URI, BLOG_ID } from "config/config";
import { useFetch } from "hooks";
import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { _debug } from "utils";

const BlogCategories = () => {
  const URL = `${API_URI}/categories/all?blog=${BLOG_ID}`;
  const { data: catsData, isLoading, hasError, errorMessage } = useFetch(URL);

  _debug(URL);

  useEffect(() => {
    if (catsData) _debug("Data fetched: ", catsData);
    if (hasError) _debug("Error: ", errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasError, catsData]);

  if (isLoading || hasError) {
    return (
      <Fragment>
        {isLoading && <Spinner />}
        {hasError && <p>{errorMessage}</p>}
      </Fragment>
    );
  }

  if (!catsData) {
    return <h5>No categories founds</h5>;
  }

  return (
    <div className="sidebar-box catagories-box mb-30">
      <h4>Категории</h4>
      <ul>
        {catsData.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`/blog/category/${item.id}?blog=${BLOG_ID}`}>
                <span>{item.name}</span>
                <span className="ml-2">(20)</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BlogCategories;
