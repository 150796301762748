export const dataPricing = {
  title: "Тарифные планы",
  subtitle: "Тарифные планы",
  description:
    "Выбирете подходящий тарифный план с соответсвии с вашими задачами и потребностями бизнес",
  items: [
    {
      title: "Базовый",
      subtitle: "",
      price: 4900.0,
      description: "* - зависит от объема хранилища",

      items: [
        { title: "до 5 пользователей" },
        { title: "Минимальная отчетность" },
        { title: "10Гб архивное хранилище" },
        { title: "Размещение в облаке" },
        { title: "Однопоточный агент" },
      ],
    },
    {
      title: "Стандарт",
      subtitle: "",
      price: 9900.0,
      description: "* - зависит от объема хранилища",

      items: [
        { title: "до 20 пользователей" },
        { title: "Расширенная отчетность" },
        { title: "100Гб архивное хранилище" },
        { title: "Размещение в облаке или собственной инфраструктуре" },
        { title: "Веб-клиент и мобильный клиент" },
        { title: "7/24 поддержка" },
        { title: "Многопользовательская работа" },
      ],
    },
    {
      title: "Премиальный",
      subtitle: "",
      price: 19900.0,
      description: "* - зависит от объема хранилища",

      items: [
        { title: "Неограниченное количество пользователей" },
        { title: "Расширенная отчетность" },
        { title: "до 10Тб архивное хранилище" },
        { title: "Веб-клиент и мобильный клиент" },
        { title: "7/24 расширенная поддержка" },
        { title: "Многопользовательская работа" },
      ],
    },
  ],
};
