import { isDevelopment } from "components/utils/Utils";
import { API_URI, BLOG_ID } from "config/config";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { _debug } from "utils";
import useFetch from "../../../hooks/useFetch";
import { PageSection, SectionHeader } from "../Home";
import BlogPostCard from "./BlogPostCard";

const URL = `${API_URI}/posts/top?blog=${BLOG_ID}`;

/**
 * Component for showing Section "Top 3 Blog Posts" of the site.
 *
 * @component
 */
const BlogRelatedPosts = (props) => {
  const { data, id = "top-posts", cn = "pt-100 pb-60" } = props;
  const { data: posts, isLoading, hasError, errorMessage } = useFetch(URL);
  const [postTop, setPostTop] = useState(undefined);

  const SectionContent = (props) => {
    const { data } = props;

    return (
      <div className="row equal">
        {data &&
          data.map((item, index) => {
            return <BlogPostCard data={item} index={index} key={index} />;
          })}
      </div>
    );
  };

  useEffect(() => {
    _debug("Data fetched: ", posts);
    if (hasError) _debug("Error: ", errorMessage);
    if (isDevelopment && !posts) {
    }
    if (posts) setPostTop(posts.slice(0, 2));
    //console.log(loading);
    //console.log(error);
  }, [isLoading, hasError, posts]);

  if (isLoading || hasError) return null;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={data}
          showSubtitle={false}
          showDescription={false}
        />
        <SectionContent data={postTop} />
      </div>
    </PageSection>
  );
};

BlogRelatedPosts.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

BlogRelatedPosts.defaultProp = {};

export default BlogRelatedPosts;
