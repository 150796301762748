import { EventGA } from "components/utils/Analytics";
import { AnaliticsEvent } from "components/utils/Analytics/Analytics";
import { API_URI, BLOG_ID } from "config/config";

export const registerSubscription = async (data) => {
  const bodyExtended = JSON.stringify({ ...data, blog: BLOG_ID });

  // GA
  EventGA(
    AnaliticsEvent.subscribe.category,
    AnaliticsEvent.subscribe.action,
    AnaliticsEvent.subscribe.label
  );

  const response = await fetch(`${API_URI}/site/event/v1/subscribe`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    },
    //mode: "no-cors",
    // body: JSON.stringify(data),
    body: bodyExtended,
  });

  return response.json();
};

export const registerFAQQuestion = async (data) => {
  const bodyExtended = JSON.stringify({ ...data, blog: BLOG_ID });

  // GA
  EventGA(
    AnaliticsEvent.askQuestion.category,
    AnaliticsEvent.askQuestion.action,
    AnaliticsEvent.askQuestion.label
  );

  const response = await fetch(`${API_URI}/site/event/v1/question`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    },
    //mode: "no-cors",
    // body: JSON.stringify(data),
    body: bodyExtended,
  });

  return response.json();
};

export const registerUserContact = async (data) => {
  const bodyExtended = JSON.stringify({ ...data, blog: BLOG_ID });

  // GA
  EventGA(
    AnaliticsEvent.contact.category,
    AnaliticsEvent.contact.action,
    AnaliticsEvent.contact.label
  );

  const response = await fetch(`${API_URI}/site/event/v1/contact`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    },
    //mode: "no-cors",
    // body: JSON.stringify(data),
    body: bodyExtended,
  });

  return response.json();
};

export const registerOrder = async (data) => {
  const bodyExtended = JSON.stringify({ ...data, blog: BLOG_ID });

  // GA
  EventGA(
    AnaliticsEvent.order.category,
    AnaliticsEvent.order.action,
    AnaliticsEvent.order.label
  );

  const response = await fetch(`${API_URI}/site/event/v1/order`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    },
    //mode: "no-cors",
    // body: JSON.stringify(data),
    body: bodyExtended,
  });

  return response.json();
};
