import { dataForm } from "data";
import { FloatingLabel, Form } from "react-bootstrap";

export const InputFloatingLabelSubject = (props) => {
  const { label, placeholder, required, register, errors, ...otherProps } =
    props;

  return (
    <FloatingLabel>
      <Form.Select
        {...register("subject", {})}
        {...otherProps}
        aria-label={dataForm.msgHelpSubject}
        aria-describedby="formSubjectHelpBlock"
        defaultValue="message"
      >
        <option>{placeholder || dataForm.msgHelpSubject}</option>
        <option value="message">Сообщение</option>
        <option value="error">Ошибка</option>
        <option value="support">Обращение</option>
      </Form.Select>
      <Form.Label>{label || "Subject"}</Form.Label>
    </FloatingLabel>
  );
};

const InputGroupSubject = (props) => {
  const {
    label,
    placeholder,
    help,
    required,
    register,
    errors,
    id,
    ...otherProps
  } = props;

  return (
    <Form.Group controlId={id || "formSubject"}>
      <InputFloatingLabelSubject
        label={label}
        required={required}
        register={register}
        errors={errors}
        {...otherProps}
      />
      {help && (
        <Form.Text id={id + "HelpBlock" || "formSubjectHelpBlock"} muted>
          {help}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default InputGroupSubject;
